import { API } from "helpers/utils";
import { isTokedExpired } from "services/util";
import store from "store";

const addProject = (formData, dispatch = null) => {
  const data = {
    ...formData
  };

  if (formData["logo"]) {
    data["logo"] = formData["logo"].map((item, idx) => ({
      id: idx + 1,
      fileName: item["uploadFileName"],
      action: "I",
    }));
  }

  return API().post('/api/project/create', data).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    throw error.response.data;
  });
};

const editProject = (formData, dispatch = null) => {
  const mandatoryData = {
    ...formData
  };

  return API().patch('/api/project/edit', mandatoryData).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    throw error.response.data;
  });
};

const serialize = obj => Object.keys(obj).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])).join('&');

const listProject = (formData, dispatch = null) => {
  const url = "/api/project/list?" + serialize(formData);

  return API().get(url).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    throw error.response.data;
  });
};

const listProjectUsers = (formData, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/project/get-users?adminUserId=" + adminUserId + "&projectId=" + formData.projectid + "&order=DESC&page=" + formData.pageNum + "&page_size=10";

  return API().get(url).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    throw error.response.data;
  });
};

const listAdminLogs = ({ type, page, dispatch = null }) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/logs?adminUserId=" + adminUserId + `&limit=20&page=${page}&type=` + type;
  return API().get(url).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
};

const deleteProject = (projectId, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const data = {
    "adminUserId": adminUserId,
    "projectId": projectId
  }

  return API().patch('/api/project/delete-project', data).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const getProjectDetail = (projectId, dispatch = null) => {
  if (store.getState().auth.user) {
    const adminUserId = store.getState().auth.user.userId;
    const url = '/api/project/details?adminUserId=' + adminUserId + '&projectId=1';
    return API().get(url).then((response) => {
      return response.data;
    }).catch(e => {
      if (e.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, e.response.data);
      }
      throw e.response.data;
    });
  }
  return null
}

const addProjectPhase = (phaseName, projectId, isDefaultPhase, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const data = {
    "projectId": parseInt(projectId),
    "adminUserId": adminUserId,
    "projectPhaseTitle": phaseName,
    "isDefault": false
  }
  if (isDefaultPhase == true || isDefaultPhase == false) {
    data["isDefault"] = isDefaultPhase;
  }
  return API().post('/api/project-phase/create', data).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const updateProjectPhase = (phaseName, phaseId, projectId, isDefaultPhase, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const data = {
    "adminUserId": adminUserId,
    "projectId": parseInt(projectId),
    "projectPhaseTitle": phaseName,
    "isDefault": true,
    "projectPhaseId": phaseId
  }
  if (isDefaultPhase == true || isDefaultPhase == false) {
    data["isDefault"] = isDefaultPhase;
  }

  if (phaseId) {
    data["phaseId"] = phaseId;
  }
  return API().patch('/api/project-phase/edit', data).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const listProjectPhase = (projectId, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = '/api/project-phase/get-all?page_size=10000&projectId=' + projectId + '&adminUserId=' + adminUserId;

  return API().get(url).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const listSystemSubSystemData = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const url = `/api/project/get-system-subsystem-parent?userId=${userId}`;

  return API().get(url).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const getSearchData = ({ text: searchText, page = 1 }, dispatch = null) => {

  const userId = store.getState().auth.user?.userId ?? 1;
  const role = store.getState().auth.role

  let url = "/api/user/search"
  if (role == "projectadmin") {
    url = "/api/admin/search"
  }

  const urlString = `${url}?userId=` + userId + "&searchText=" + searchText + "&page=" + page + "&page_size=10";

  if (searchText !== "") {
    return API().get(urlString)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (error.response.data.status === 401 && dispatch) {
          isTokedExpired(dispatch, error.response.data);
        } else {
          console.log(error);
          throw error.response.data;
        }
      });
  }
}

const getProjectUserDetails = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const urlString = `/api/project/get-project-users?adminUserId=${userId}`;

  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });

}

const getProjectSummary = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const projectId =
    (store.getState().project.projectDetail.projectId ||
      (sessionStorage.getItem("project") &&
        JSON.parse(sessionStorage.getItem("project")).projectId)) ?? 1

  const urlString = `/api/project/get-project-summary?userId=${userId}&projectId=${projectId}`;

  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const getProjectSpecificSummary = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const projectId =
    (store.getState().project.projectDetail.projectId ||
      (sessionStorage.getItem("project") &&
        JSON.parse(sessionStorage.getItem("project")).projectId)) ?? 1

  const urlString = `/api/project/get-project-specific-summary?userId=${userId}&projectId=${projectId}`;

  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const getGanttChart = (limit = 50, dispatch = null) => {
  const projectId = 1
  const userId = store.getState().auth.user?.userId ?? 1;

  const urlString = `/api/project/get-gantt-chart-data?userId=${userId}&projectId=${projectId}&limit=${limit}`;

  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const projectService = {
  addProject,
  editProject,
  listProject,
  listProjectUsers,
  deleteProject,
  getProjectDetail,
  addProjectPhase,
  updateProjectPhase,
  listProjectPhase,
  listSystemSubSystemData,
  getSearchData,
  getProjectUserDetails,
  getProjectSummary,
  getProjectSpecificSummary,
  getGanttChart,
  listAdminLogs
};
export default projectService;

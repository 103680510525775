import { API } from "helpers/utils";
import { isTokedExpired } from "services/util";
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

export const getTagList = async ({ sortBy, order, page, searchData, isFollower, systemSubSystemId }, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1

    let url = `/api/tag/get-tag-list?userId=${userId}&sortBy=${sortBy}&order=${order}&page_size=10&page=${page}&searchData=${searchData}&isFollower=${isFollower}`

    if (systemSubSystemId) {
      url += `&systemSubSystemId=${systemSubSystemId}`;
    }
    const result = await API().get(url)
    return result.data

  } catch (error) {
    console.log("error", error);
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    } else if (error.response.status !== 404) {
      notifyPopup("error", "Error", error.response.data.error)
    }
    return null
  }
}

export const addTagComment = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const data = {
      ...values,
      userId
    }
    const url = `/api/tag/add-comment`;
    const result = await API().post(url, data);
    return result.data;
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    notifyPopup("error", "Error", error.response.data.error)
    return null
  }
}

export const getTagHistory = async (tagId, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/get-history?userId=${userId}&id=${tagId}`;
    const result = await API().get(url);
    return result.data;
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    notifyPopup("error", "Error", error.response.data.error)
    return null
  }
}

export const createTag = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/create`
    const data = {
      ...values,
      userId
    }
    if (data["attachments"]) {
      data["attachments"] = data["attachments"].map(
        (item, idx) => ({
          id: idx + 1,
          fileName: item["uploadFileName"],
          action: "I",
        })
      );
    }
    const result = await API().post(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    throw error
  }
}

const getEditTagAttachments = (data) => {
  if (data["attachments"] && data["attachments"].length > 0) {
    const arr = [];
    data["attachments"].forEach((item, idx) => {
      if (item.uploadFileName) {
        arr.push({
          id: idx + 1,
          fileName: item["uploadFileName"],
          action: "I",
        });
      }
    });
    data["attachments"] = arr;
  }
}

const getEditTagRemovedFilelist = (data) => {
  if (data["removedFileList"] && data["removedFileList"].length > 0) {
    const arr = [];
    data["removedFileList"].forEach((item) => {
      if (Number.isInteger(item.uid)) {
        arr.push({
          id: item.uid,
          fileName: item["name"],
          action: "R",
        });
      }
    });
    if (data["attachments"] && data["attachments"].length > 0) {
      data["attachments"] = [...data["attachments"], ...arr];
    } else {
      data["attachments"] = [...arr];
    }
  }
}

export const editTag = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/edit`
    const data = {
      ...values,
      userId
    }

    getEditTagAttachments(data)

    getEditTagRemovedFilelist(data)
    const result = await API().post(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    throw error
  }
}

export const assignTagGroup = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/assign-tag-group`
    const data = {
      ...values,
      userId
    }
    const result = await API().post(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const getTagDetails = async (tagId, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/get-tag-details?userId=${userId}&tagId=${tagId}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const searchDelay = async (searchText, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/delay/search-delays?userId=${userId}&searchText=${searchText}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const searchRisk = async (searchText) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/risk/search-risk?userId=${userId}&searchText=${searchText}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    console.log("error", error);
    return null
  }
}

export const searchSupplierDiscipline = async (searchText, searchType) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/search-supplier-discipline?userId=${userId}&searchText=${searchText}&searchType=${searchType}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    console.log("error", error);
    return null
  }
}

export const deleteTags = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag/delete-tags`
    const data = {
      ...values,
      userId
    }
    const result = await API().patch(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const followTag = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = "/api/tag/follow"
    const data = {
      ...values,
      userId
    }

    const result = await API().post(url, data)
    return result.data;
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}
import "./IconLetter.scss";

export default function({letter}){
    return (
        <>
            <div className="icon-letter">
                <div className="letter">
                    {letter.toUpperCase()}
                </div>
            </div>
        </>
    );
}
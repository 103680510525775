export const ADMIN_ROUTES = [
  '/admin/projects/update',
  '/admin/users',
  '/admin/imports',
  '/admin/reference-data',
  '/admin/logs',
]

export const ICORA_ROUTES = [
  '/schedule',
  '/project/alldata/delays',
  '/project/alldata/delays/add',
  '/project/alldata/delays/add/task/:var/:id',
  '/project/alldata/delays/view/:delayId',
  '/project/alldata/delays/view/:delayId/tasks/:taskId',
  '/project/alldata/delays/view/:delayId/edit/:delayId',
  '/project/alldata/risks',
  '/project/alldata/risks/add',
  '/project/alldata/risks/add/task/:var/:id',
  '/project/alldata/risks/view/:riskId',
  '/project/alldata/risks/view/:riskId/tasks/:taskId',
  '/project/alldata/risks/view/:riskId/edit/:riskId',
  '/project/alldata/tasks',
  '/project/alldata/tasks/edit/:taskId',
  '/project/alldata/tasks/add',
  '/project/alldata/tasks/add/:var/:id',
  '/vor',
  '/project/commissioning-readiness',
  '/project/csu',
  '/project/csu/link/:csuId',
  '/project/tag-group',
  '/project/tag-group/add',
  '/project/tag-group/add/:link/:type',
  '/project/tag-group/edit/:tagGroupId',
  '/project/tags',
  '/project/tags/add',
  '/project/tags/edit/:tagId',
  '/project/tags/group',
  '/project/or-readiness',
  '/project/or',
  '/project/or/link/:orId',
  '/project/or/:orCategory',
  '/project/or/:orCategory/link/:orId',
  '/notification'
]

export const CLIENT_ROUTES = [
  '/project/alldata/delays',
  '/project/alldata/delays/add',
  '/project/alldata/delays/add/task/:var/:id',
  '/project/alldata/delays/view/:delayId',
  '/project/alldata/delays/view/:delayId/tasks/:taskId',
  '/project/alldata/delays/view/:delayId/edit/:delayId',
  '/project/alldata/risks',
  '/project/alldata/risks/add',
  '/project/alldata/risks/add/task/:var/:id',
  '/project/alldata/risks/view/:riskId',
  '/project/alldata/risks/view/:riskId/tasks/:taskId',
  '/project/alldata/risks/view/:riskId/edit/:riskId',
  '/vor',
  '/project/commissioning-readiness',
  '/project/csu',
  '/project/csu/link/:csuId',
  '/project/or-readiness',
  '/project/or',
  '/project/or/link/:orId',
  '/project/or/:orCategory',
  '/project/or/:orCategory/link/:orId'
]
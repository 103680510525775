import { Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect } from 'react';

import DebounceSelect from 'helpers/DebounceSelect';
import { IconButton } from 'components/button/Button';
import PageHeadingMultiButtons from 'components/PageHeading/PageHeadingMultiButtons';
import addIcon from 'assets/icons/add.png';
import { assignTagGroup } from 'services/projectmgmt/tag.service';
import downloadIcon from 'assets/icons/download.png';
import notifyPopup from 'helpers/notificationHelper';
import { searchTagGroup } from 'services/projectmgmt/tagGroup.service';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

async function fetchTagGroup(searchText, searchType) {
	if (searchText && searchText.trim()) {
		return searchTagGroup(searchText, searchType)
			.then((res) => res.records.list)
			.then((body) =>
				body.map((tag) => {
					const name = tag.mechanical_taskId
						? `${tag.mechanical_taskId}-${tag.mechanical_taskName}`
						: `${tag.supply_taskId}-${tag.supply_taskName}`;
					return {
						label: tag.tagGroupName,
						value: `${name}##${tag.tagGroupId}`,
					};
				})
			);
	}
}

export default function LinkTagGroup() {
	const [form] = Form.useForm();
	const formRef = React.useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [value, setValue] = React.useState([]);
	const [searchType, setSearchType] = React.useState('supplyChainMilestone');
	const [existingTagGroup, setExistingTagGroup] = React.useState(null);

	const closeLink = () => {
		return '/project/tags';
	};

	const onClickTagGroup = async (type) => {
		let formValues = {};
		try {
			formValues = await form.validateFields();
		} catch (error) {
			formValues = error.values;
		}
		sessionStorage.setItem(
			'add-tag-link-values',
			JSON.stringify({
				formValues,
				searchType,
			})
		);

		if (type === 'supplyChainMilestone') {
			navigate(`/project/tag-group/add/supplyChain/link`);
		} else {
			navigate(`/project/tag-group/add/mechanicalCompletion/link`);
		}
	};

	const getTagGroupId = (formValues) => {
		if (existingTagGroup) {
			return existingTagGroup['tagGroupId'];
		}
		if (formValues['tag-group']) {
			return formValues['tag-group'].value.split('##')[1];
		}
		return 1;
	};

	const getDesc = () => {
		if (existingTagGroup) {
			return existingTagGroup['milestoneName'];
		} else if (value && value.value) {
			return value.value.split('##')[0];
		}
		return '';
	};

	const onSubmit = async () => {
		if (sessionStorage.getItem('selectedTags')) {
			const formValues = await form.validateFields();
			const selectedTags = JSON.parse(sessionStorage.getItem('selectedTags'));
			const data = {
				tagId: selectedTags,
				tagGroupId: getTagGroupId(formValues),
				tagGroupType: searchType,
			};
			await assignTagGroup(data, dispatch);
			notifyPopup('success', 'Success', 'tags successfully linked');
			navigate(closeLink());
		} else {
			notifyPopup('info', 'Success', 'Tags not selected');
		}
	};

	useEffect(() => {
		if (sessionStorage.getItem('link')) {
			const link = JSON.parse(sessionStorage.getItem('link'));
			const linkKey = Object.keys(link);

			setExistingTagGroup({
				...link[linkKey[0]],
			});

			//remove old link
			sessionStorage.removeItem('link');
		}
		if (sessionStorage.getItem('add-tag-link-values')) {
			const values = JSON.parse(sessionStorage.getItem('add-tag-link-values'));
			form.setFieldsValue(values.formValues);

			if (values.searchType) {
				setSearchType(values.searchType);
			}
			//remove old values
			sessionStorage.removeItem('add-tag-link-values');
		}
	}, []);

	return (
		<div className='link-tag-container'>
			<PageHeadingMultiButtons
				title={'Add Tags to Existing Group'}
				closeLink={closeLink()}
				subTitle
			/>
			<Form layout='vertical' form={form} ref={formRef}>
				<Form.Item name='type' label='Tag Group Type'>
					<Select onChange={(value) => setSearchType(value)} name='type'>
						<Option value='supplyChainMilestone'>SupplyChain</Option>
						<Option value='mechanicalCompletionMilestone'>Completion</Option>
					</Select>
				</Form.Item>
				<Row gutter={8}>
					{existingTagGroup ? (
						<Col span={12}>
							<Form.Item>
								<Input disabled value={existingTagGroup['name']} />
							</Form.Item>
						</Col>
					) : (
						<>
							<Col span={12}>
								<Form.Item name='tag-group' label='Tag Group'>
									<DebounceSelect
										showSearch
										multiple={false}
										value={value}
										placeholder='Select Tag Group'
										fetchOptions={(text) => fetchTagGroup(text, searchType)}
										onChange={(newValue) => {
											setValue(newValue);
										}}
										style={{
											width: '100%',
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<div
									style={{
										width: '100%',
										height: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<IconButton
										icon={addIcon}
										text='New'
										onClick={() => onClickTagGroup(searchType)}
									/>
								</div>
							</Col>
						</>
					)}
				</Row>
				<Form.Item label='Supply Chain Milestone'>
					<Input disabled value={getDesc()} />
				</Form.Item>
				<div
					style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
				>
					<IconButton icon={downloadIcon} text='Save' onClick={onSubmit} />
				</div>
			</Form>
		</div>
	);
}

import "./SideBar.scss";

import React, { useCallback, useEffect } from "react";

import { Skeleton } from "antd";
import getMenuSideBar from "../../helpers/menuHelper";
import projectService from 'services/admin/project.service';
import { useDispatch } from "react-redux";

function SideBar({ menus, setAdmin }) {
  const [rfsuData, setRfsuData] = React.useState(null);
  const dispatch = useDispatch();
  const fetchRFSUData = useCallback(async () => {
    try {
      const data = await projectService.getProjectSummary(dispatch);
      if (data) {
        setRfsuData(data.records);
      }
    } catch (error) {
      console.log('error', error);
    }
  })

  useEffect(() => {
    if (rfsuData === null) {
      fetchRFSUData()
    }
  }, [fetchRFSUData, rfsuData]);


  return (
    <div className="sidebar-component" style={{ height: "100%", minHeight: "100vh" }}>
      <div className="menu-section">
        <div className="rfsu-container">
          {rfsuData ? (
            <div>
              <h3>Forecast RFSU</h3>
              <h3>
                {rfsuData.forecastRFSU}
              </h3>
            </div>
          ) : (
            <div>
              <Skeleton.Input size="small" style={{ marginBottom: "12px", height: '15px' }} active />
              <Skeleton.Input size="small" style={{ marginBottom: "12px", height: '15px' }} active />
            </div>)}
        </div>
        {getMenuSideBar(menus, setAdmin)}
      </div>
    </div>
  );
}

export default SideBar;

import { Link } from "react-router-dom";
import { Menu } from "antd";
import React from "react";
import folderIcon from 'assets/icons/folder.svg';
import homeIcon from 'assets/icons/home.svg';

function getMenuSideBar(menus, setAdmin) {
  const keys = []
  const getMenu = function (innerMenu) {
    const items = []

    innerMenu.forEach((menu, i) => {
      const temp = {}
      temp["icon"] = <img src={menu.icon} />
      if (menu.type === "sub") {
        temp["children"] = getMenu(menu.child)
        temp["key"] = "sub2" + menu.title + i
        temp["label"] = <Link onClick={() => setAdmin(menu.showAdmin)} style={{ width: "100%", display: "block" }} to={menu.anchorPath}>{menu.title}</Link>
        keys.push("sub2" + menu.title + i)
      } else {
        temp["key"] = menu.anchorTitle + "-" + i + "-item"
        temp["label"] = <Link onClick={() => setAdmin(menu.showAdmin)} to={menu.anchorPath}>{menu.anchorTitle}</Link>
        keys.push(menu.anchorTitle + "-" + i + "-item")
      }
      items.push(temp)
    })
    return items
  }

  return <Menu
    items={getMenu(menus)}
    mode="inline"
    theme="light"
    openKeys={keys}
  />
}

export default getMenuSideBar;

export const getMenuData = (roleName, showAdmin) => {
  const menuData = [];
  const homeMenu = {
    type: 'primary',
    icon: homeIcon,
    anchorPath: '/admin/',
    anchorTitle: 'Home',
    showAdmin: false
  };
  const delayMenu = {
    type: "primary",
    icon: folderIcon,
    anchorPath: "/project/alldata/delays",
    anchorTitle: "Delays",
    showAdmin: false
  }
  const riskMenu = {
    type: "primary",
    icon: folderIcon,
    anchorPath: "/project/alldata/risks",
    anchorTitle: "Risks",
    showAdmin: false
  }

  const projectMenu = {
    type: "sub",
    icon: folderIcon,
    anchorPath: "/admin/projects/update",
    title: "Project Details",
    showAdmin: true,
    child: [
      {
        type: 'primary',
        anchorPath: '/admin/users',
        anchorTitle: 'User',
        showAdmin: true,
      },
      {
        type: 'primary',
        anchorPath: '/admin/imports',
        anchorTitle: 'Imports',
        showAdmin: true,
      },
      {
        type: 'primary',
        anchorPath: '/admin/reference-data',
        anchorTitle: 'Reference Data',
        showAdmin: true,
      },
      {
        type: 'primary',
        anchorPath: '/admin/logs',
        anchorTitle: 'Logs',
        showAdmin: true,
      },
    ]
  }
  const scheduleMenu = {
    type: "sub",
    icon: folderIcon,
    anchorPath: "/schedule",
    title: "Schedule",
    showAdmin: false,
    child: [
      {
        type: 'primary',
        anchorPath: '/project/alldata/delays',
        anchorTitle: 'Delays',
        showAdmin: false,
      },
      {
        type: 'primary',
        anchorPath: '/project/alldata/risks',
        anchorTitle: 'Risks',
        showAdmin: false,
      },
      {
        type: 'primary',
        anchorPath: '/project/alldata/tasks',
        anchorTitle: 'Tasks',
        showAdmin: false,
      },
    ]
  }
  const commissoningMenu = {
    type: "sub",
    icon: folderIcon,
    anchorPath: "/project/commissioning-readiness",
    title: "Commissioning Readiness",
    showAdmin: false,
    child: [
      {
        type: 'primary',
        anchorPath: '/project/csu',
        anchorTitle: 'CSU Activities',
        showAdmin: false,
      },
      {
        type: 'primary',
        anchorPath: '/project/tag-group',
        anchorTitle: 'Tag Groups',
        showAdmin: false,
      },
      {
        type: 'primary',
        anchorPath: '/project/tags',
        anchorTitle: 'Tags',
        showAdmin: false,
      }
    ]
  }
  const operatonalMenu = {
    type: "sub",
    icon: folderIcon,
    anchorPath: "/project/or-readiness",
    title: "Operational Readiness",
    showAdmin: false,
    child: [
      {
        type: 'primary',
        anchorPath: '/project/or',
        anchorTitle: 'OR Activities',
        showAdmin: false,
      }
    ]
  }

  const verficationMenu = {
    type: "sub",
    icon: folderIcon,
    anchorPath: "/vor",
    title: "Verification Of Readiness",
    showAdmin: false,
    child: [commissoningMenu, operatonalMenu]
  }

  if ((roleName === "projectadmin" && !showAdmin) || (roleName === 'icorauser')) {
    homeMenu.anchorPath = '/project';
    menuData.push(homeMenu);
    menuData.push(scheduleMenu);
    menuData.push(verficationMenu)
  } else if (roleName === "projectadmin" && showAdmin) {
    menuData.push(projectMenu);
  } else if (roleName === "client") {
    homeMenu.anchorPath = "/project"
    menuData.push(homeMenu)
    menuData.push(delayMenu)
    menuData.push(riskMenu)
    const commissioningChild = [...commissoningMenu.child]
    commissioningChild.pop()
    commissioningChild.pop()
    commissoningMenu.child = [...commissioningChild]
    menuData.push(verficationMenu)
  }

  return menuData
}

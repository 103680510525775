import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const langResource = {
    en: {
      translations: require('./locales/en/translations.json')
    }
  };

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: langResource,
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = Object.keys(langResource);

export default i18n;
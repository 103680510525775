import { API } from "helpers/utils";
import { isTokedExpired } from "services/util";
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

const getFileUrl = (keyName, action = "get", type = "", dispatch = null) => {
  let url = "/api/files/get-creds?actionType=" + action + "&keyName=" + keyName;

  if (type) {
    url += `&type=${type}`
  }

  return API().get(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      console.log(error);
      throw error.response.data;
    });
}

const exportData = (data, dispatch = null) => {
  const { type, startDate, endDate, isCompleted, isActive } = data
  const userId = store.getState().auth.user?.userId ?? 1

  let url = `/api/report/export-schedule?userId=${userId}&type=${type}`

  if (startDate && endDate) {
    url += `&startDate=${startDate}&endDate=${endDate}`
  }

  if (isCompleted !== null) {
    url += `&isCompleted=${isCompleted}`
  }

  if (isActive !== null && isActive !== undefined) {
    url += `&isActive=${isActive}`
  }

  return API().get(url)
    .then(result => {
      return result.data
    }).catch(err => {
      if (err.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, err.response.data);
      } else if (err.response.status === 404) {
        notifyPopup("error", "Error", "No Data Found")
      } else {
        notifyPopup("error", "Error", "Something went wrong")
      }
      return null
    })
}

const importData = async (uploadProgress, url, file) => {
  return API(uploadProgress).put(url, file)
    .then(result => {
      return result.data
    }).catch(err => {
      console.log("error", err);
      throw err.response.data;
    })
}

const getImportLastUpdated = async (dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `api/project/get-import-lastupdated?userId=${userId}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    console.log("error", error);
    return null
  }
}

const updateS3Status = async (data, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = '/api/files/update-status'
    const values = {
      ...data,
      userId
    }
    const result = await API().post(url, values)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    console.log("error", error);
    return null
  }
}

const getS3Status = async (fileName, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/files/get-status?userId=${userId}&fileName=${fileName}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    console.log("error", error);
    return null
  }
}

const fileService = {
  getFileUrl,
  exportData,
  importData,
  getImportLastUpdated,
  updateS3Status,
  getS3Status
};
export default fileService;
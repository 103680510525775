import { API } from "helpers/utils";
import { isTokedExpired } from "services/util";
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

export const getTagGroupList = async ({ page, order, sortBy, searchData, isFollower }, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag-group/list?userId=${userId}&page=${page}&order=${order}&page_size=10&sortBy=${sortBy}&searchData=${searchData}&isFollower=${isFollower}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    console.log("error", error);
    return null
  }
}

export const createTagGroup = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag-group/create`
    const data = {
      ...values,
      userId
    }
    if (data["attachments"]) {
      data["attachments"] = data["attachments"].map(
        (item, idx) => ({
          id: idx + 1,
          fileName: item["uploadFileName"],
          action: "I",
        })
      );
    }
    const result = await API().post(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    throw error
  }
}

const getEditTagAttachments = (data) => {
  if (data["attachments"] && data["attachments"].length > 0) {
    const arr = [];
    data["attachments"].forEach((item, idx) => {
      if (item.uploadFileName) {
        arr.push({
          id: idx + 1,
          fileName: item["uploadFileName"],
          action: "I",
        });
      }
    });
    data["attachments"] = arr;
  }
}

const getEditTagRemovedFilelist = (data) => {
  if (data["removedFileList"] && data["removedFileList"].length > 0) {
    const arr = [];
    data["removedFileList"].forEach((item) => {
      if (Number.isInteger(item.uid)) {
        arr.push({
          id: item.uid,
          fileName: item["name"],
          action: "R",
        });
      }
    });
    if (data["attachments"] && data["attachments"].length > 0) {
      data["attachments"] = [...data["attachments"], ...arr];
    } else {
      data["attachments"] = [...arr];
    }
  }
}

export const editTagGroup = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag-group/edit`
    const data = {
      ...values,
      userId
    }

    getEditTagAttachments(data)

    getEditTagRemovedFilelist(data)
    const result = await API().post(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    throw error
  }
}

export const getTagGroupDetails = async (tagGroupId, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag-group/get-details?tagGroupId=${tagGroupId}&userId=${userId}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const deleteTagGroup = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag-group/delete`
    const data = {
      ...values,
      userId
    }
    const result = await API().patch(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const searchTagGroup = async (searchText, searchType, tagGroupOnly = true) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/tag-group/search?searchType=${searchType}&userId=${userId}&searchText=${searchText}&tagGroupOnly=${tagGroupOnly}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    console.log("error", error);
    return null
  }
}

export const addTagGroupComment = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const data = {
      ...values,
      userId
    }
    const url = `/api/taggroup/add-comment`;
    const result = await API().post(url, data);
    return result.data;
  } catch (error) {
    console.log("error", error);
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    } else {
      notifyPopup("error", "Error", error.response.error)
    }
    return null
  }
}

export const getTagGroupHistory = async (tagId, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/taggroup/get-history?userId=${userId}&id=${tagId}`;
    const result = await API().get(url);
    return result.data;
  } catch (error) {
    console.log("error", error);
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    } else {
      notifyPopup("error", "Error", error.response.error)
    }
    return null
  }
}

import { AuthProvider, VerifyToken } from "guards/AuthProvider"
import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import AddTagGroup from "pages/projectmgmt/commissioningReadiness/tags/AddTagGroup.jsx";
import AuthHomePage from "pages/auth/Home";
import ConfirmPassword from "pages/auth/ConfirmPassword.jsx";
import FallbackHomeLayout from "layouts/client/HomeLayout";
import ForgetPassword from "pages/auth/ForgetPassword.jsx";
import ImportFile from "pages/admin/imports/ImportFile.jsx";
import LinkCSU from "pages/projectmgmt/commissioningReadiness/csuActivities/LinkCSU.jsx";
import LinkOR from "pages/projectmgmt/opeartionalReadiness/ORActivies/LinkOR.jsx";
import LinkTagGroup from "pages/projectmgmt/commissioningReadiness/tags/LinkTagGroup.jsx";
import Logout from "../layouts/logout/Logout.jsx";
import NormalLoginForm from "pages/auth/Login";
import ResetPassword from "pages/auth/ResetPassword.jsx";
import { useSelector } from "react-redux";

// Admin Page Imports
const AdminLogs = lazy(()=>import("pages/admin/logs/AdminLogs.jsx"));
const ReferenceOverview = lazy(()=> import("pages/admin/referenceData/ReferenceOverview.jsx"));

const Users = lazy(() => import("pages/admin/users/Overview"));
const AddUser = lazy(() => import("pages/admin/users/AddUser"));
const EditUser = lazy(() => import("pages/admin/users/EditUser"));

const Projects = lazy(() => import("pages/admin/projects/Overview"));
const ProjectView = lazy(() => import("pages/admin/projects/ProjectView"));
const AddProject = lazy(() => import("pages/admin/projects/AddProject"));
const EditProject = lazy(() => import("pages/admin/projects/EditProject"));

// Client Page imports
const ProjectDashboard = lazy(() => import("pages/projectmgmt/Dashboard"));

const Tasks = lazy(() => import("pages/projectmgmt/tasks/Overview"));
const AddTask = lazy(() => import("pages/projectmgmt/tasks/AddTask"));
const EditTask = lazy(() => import("pages/projectmgmt/tasks/EditTask"));

const Risks = lazy(() => import("pages/projectmgmt/risks/Overview"));
const AddRisk = lazy(() => import("pages/projectmgmt/risks/AddRisk"));
const ViewRiskTasks = lazy(() =>
  import("pages/projectmgmt/risks/ViewRiskTasks")
);
const EditRisk = lazy(() => import("pages/projectmgmt/risks/EditRisk"));
const EditRiskTasks = lazy(() =>
  import("pages/projectmgmt/risks/EditRiskTasks")
);

const Delays = lazy(() => import("pages/projectmgmt/delays/Overview"));
const AddDelay = lazy(() => import("pages/projectmgmt/delays/AddDelay"));
const EditDelay = lazy(() => import("pages/projectmgmt/delays/EditDelay"));
const EditDelayTasks = lazy(() =>
  import("pages/projectmgmt/delays/EditDelayTasks")
);
const ViewDelayTasks = lazy(() =>
  import("pages/projectmgmt/delays/ViewDelayTasks")
);
const SearchResult = lazy(()=>import("components/SearchHeader/Searchresult.jsx"));
const Notification = lazy(()=>import("pages/projectmgmt/notification/Notification.jsx"));
const CommissioningReadiness = lazy(()=>import("pages/projectmgmt/commissioningReadiness/CommissioningReadiness.jsx"));
const ImportOverview = lazy(()=>import("pages/admin/imports/ImportOverview.jsx"));
const Schedule = lazy(()=> import("pages/projectmgmt/schedule/Schedule"));
const CSUOverview = lazy(()=> import("pages/projectmgmt/commissioningReadiness/csuActivities/CSUOverview.jsx"));
const VOR = lazy(()=> import("pages/projectmgmt/vor/VOR.jsx"));

const TagGroupOverview = lazy(()=> import("pages/projectmgmt/commissioningReadiness/tags/TagGroupOverview.jsx"));
const EditTagGroup = lazy(()=>import("pages/projectmgmt/commissioningReadiness/tags/EditTagGroup.jsx"));

const TagsOverview = lazy(()=> import("pages/projectmgmt/commissioningReadiness/tags/TagsOverview.jsx"));
const EditTag = lazy(()=>import("pages/projectmgmt/commissioningReadiness/tags/EditTag.jsx"));
const AddTag = lazy(()=>import("pages/projectmgmt/commissioningReadiness/tags/AddTag.jsx"));

const ORReadiness = lazy(()=> import("pages/projectmgmt/opeartionalReadiness/ORReadiness.jsx"));
const ORActivites = lazy(()=> import("pages/projectmgmt/opeartionalReadiness/ORActivies/ORActivites.jsx"));

const PublicRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<AuthHomePage />}
      >
        <Route path="login/:type" element={<NormalLoginForm />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="/user/resetPassword" element={<ResetPassword />} />
      <Route path="/user/forgetPassword" element={<ForgetPassword />} />
      <Route path="/user/confirmPassword" element={<ConfirmPassword />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

const PrivateRoute = () => {
  return (
    <>
      <Suspense fallback={<FallbackHomeLayout />}>
        <Routes>
          <Route
            path="/"
            element={<AuthHomePage />}
          >
            <Route path="reset" element={<NormalLoginForm />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/admin/users"
            element={
              <AuthProvider>
                <Users />
              </AuthProvider>
            }
          >
            <Route path="add" element={<AddUser />} />
            <Route path="edit/:id" element={<EditUser />} />
          </Route>
          <Route
            path="/admin/projects"
            element={
              <AuthProvider>
                <Projects />
              </AuthProvider>
            }
          >
            <Route path="edit/:id" element={<EditProject />} />
          </Route>
          <Route
            path="/admin/projects/:projectid"
            element={<ProjectView />}
          />
          <Route
            path="/admin/projects/add"
            element={<AddProject />}
          />
          <Route
            path="/admin/projects/update"
            element={
              <AuthProvider>
                <EditProject />
              </AuthProvider>
            }
          />
          <Route
            path="/admin/reference-data"
            element={
              <AuthProvider>
                <ReferenceOverview />
              </AuthProvider>
            }
          />
          <Route
            path="/admin/imports"
            element={
              <AuthProvider>
                <ImportOverview />
              </AuthProvider>
            }
          >
            <Route path="edit/:type" element={<ImportFile />} />
          </Route>
          <Route
            path="/admin/logs"
            element={
              <AuthProvider>
                <AdminLogs />
              </AuthProvider>
            }
          />

          <Route
            path="/project/"
            element={<ProjectDashboard />}
          />

          {/* Schedule */}
          <Route
            path="/schedule"
            element={
              <AuthProvider>
                <Schedule />
              </AuthProvider>
            }
          />

          {/* vor */}
          <Route
            path="/vor"
            element={
              <AuthProvider>
                <VOR />
              </AuthProvider>
            }
          />

          {/* Notification */}
          <Route
            path="/notification"
            element={
              <AuthProvider>
                <Notification />
              </AuthProvider>}
          />

          {/* commissioning readiness */}
          <Route
            path="/project/commissioning-readiness"
            element={
              <AuthProvider>
                <CommissioningReadiness />
              </AuthProvider>
            }
          />
          <Route path='/project/csu'
            element={
              <AuthProvider>
                <CSUOverview />
              </AuthProvider>
            } >
            <Route path="link/:csuId" element={<LinkCSU />} />
          </Route>
          <Route
            path={"/project/tags"}
            element={
              <AuthProvider>
                <TagsOverview />
              </AuthProvider>
            }>
            <Route
              path="add"
              element={
                <AuthProvider>
                  <AddTag />
                </AuthProvider>
              }
            />
            <Route
              path="edit/:tagId"
              element={
                <AuthProvider>
                  <EditTag />
                </AuthProvider>
              }
            />
            <Route
              path="group"
              element={
                <AuthProvider>
                  <LinkTagGroup />
                </AuthProvider>}
            />
          </Route>
          <Route
            path={"/project/tag-group"}
            element={
              <AuthProvider>
                <TagGroupOverview />
              </AuthProvider>
            }
          >
            <Route
              path="add"
              element={
                <AuthProvider>
                  <AddTagGroup />
                </AuthProvider>}
            />
            <Route
              path="add/:link/:type"
              element={
                <AuthProvider>
                  <AddTagGroup />
                </AuthProvider>}
            />
            <Route
              path="edit/:tagGroupId"
              element={
                <AuthProvider>
                  <EditTagGroup />
                </AuthProvider>
              }
            />
          </Route>
          <Route path="/project/or-readiness"
            element={
              <AuthProvider>
                <ORReadiness />
              </AuthProvider>
            }
          />
          <Route
            path="/project/or"
            element={
              <AuthProvider>
                <ORActivites />
              </AuthProvider>
            }
          >
            <Route path="link/:orId" element={<LinkOR />} />
          </Route>
          <Route
            path="/project/or/:orCategory"
            element={
              <AuthProvider>
                <ORActivites />
              </AuthProvider>
            }
          >
            <Route path="link/:orId" element={<LinkOR />} />
          </Route>

          {/* Delay Section */}
          <Route
            path="/project/alldata/delays"
            element={
              <AuthProvider>
                <Delays />
              </AuthProvider>
            }
          >
            <Route path="add" element={<AddDelay />} />
            <Route path="add/task/:var/:id" element={<AddTask />} />
          </Route>

          <Route
            path="/project/alldata/delays/view/:delayId"
            exact
            element={
              <AuthProvider>
                <ViewDelayTasks />
              </AuthProvider>
            }
          >
            <Route path="tasks/:taskId" exact element={<EditDelayTasks />} />
            <Route path="edit/:delayId" exact element={<EditDelay />} />
          </Route>

          {/* Risk Section */}
          <Route
            path="/project/alldata/risks"
            element={
              <AuthProvider>
                <Risks />
              </AuthProvider>
            }
          >
            <Route path="add" element={<AddRisk />} />
            <Route path="add/task/:var/:id" element={<AddTask />} />
          </Route>

          <Route
            path="/project/alldata/risks/view/:riskId"
            exact
            element={
              <AuthProvider>
                <ViewRiskTasks />
              </AuthProvider>
            }
          >
            <Route path="tasks/:taskId" exact element={<EditRiskTasks />} />
            <Route path="edit/:riskId" exact element={<EditRisk />} />
          </Route>

          <Route
            path="/project/alldata/tasks"
            element={
              <AuthProvider>
                <Tasks />
              </AuthProvider>
            }
          >
            <Route path="edit/:taskId" exact element={<EditTask />} />
          </Route>
          <Route
            path="/project/alldata/tasks/add"
            element={
              <AuthProvider>
                <AddTask />
              </AuthProvider>
            }
          />
          <Route
            path="/project/alldata/tasks/add/:var/:id"
            element={
              <AuthProvider>
                <AddTask />
              </AuthProvider>
            }
          />
          <Route path="/search/result" element={<SearchResult />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <VerifyToken />
      </Suspense>
    </>
  );
};

function AppRoute() {
  const userData = useSelector((state) => state.auth);
  const userFromSession = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}

  return (
    <>
      {(userData && userData.username) || (userFromSession && userFromSession.username) ? <PrivateRoute /> : <PublicRoute />}
    </>
  );
}

export default AppRoute

import { API } from "helpers/utils";
import { isTokedExpired } from "services/util";
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

export const getAllCSU = async ({ order, page, sortBy, searchData, systemSubSystemId }, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    let url = `/api/csu/get-list?userId=${userId}&order=${order}&page_size=10&page=${page}&sortBy=${sortBy}&searchData=${searchData}`
    if (systemSubSystemId) {
      url += `&systemSubSystemId=${systemSubSystemId}`;
    }

    const result = await API().get(url)
    return result.data
  } catch (error) {
    console.log("error", error);
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    } else if (error.response.status !== 404) {
      notifyPopup("error", "Error", error.response.error)
    }
    return null
  }
}

export const getCSUDetail = async (csuId, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `api/csu/get-data?userId=${userId}&csuId=${csuId}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    console.log("error", error);
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    } else {
      notifyPopup("error", "Error", error.response.error)
    }
    return null
  }
}

export const linkCSU = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/csu/link-system`
    const data = {
      ...values,
      userId
    }
    const result = await API().patch(url, data)
    return result.data
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}
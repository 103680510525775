import { API } from "helpers/utils";
import { isTokedExpired } from "services/util";
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

export const getOrList = async ({ order, page, searchData, category, sortBy, systemSubSystemId=null }, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    let url = `/api/or/get-list?order=${order}&page=${page}&page_size=10&userId=${userId}&searchData=${searchData}&category=${category}&sortBy=${sortBy}`
    if(systemSubSystemId){
      url += `&systemSubSystemId=${systemSubSystemId}`;
    }
    const result = await API().get(url)
    return result.data
  } catch (error) {
    console.log("error", error.response);
    if(error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }else if(error.response.status !== 404){
      notifyPopup("error", "Error", error.response.error)
    }
    return null
  }
}

export const getOrData = async (vorId, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/or/get-data?userId=${userId}&vorId=${vorId}`
    const result = await API().get(url)
    return result.data
  } catch (error) {
    if(error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const linkSystem = async (values, dispatch = null) => {
  try {
    const userId = store.getState().auth.user?.userId ?? 1
    const url = `/api/or/link-system`
    const data = {
      ...values,
      userId
    }
    const result = await API().patch(url, data)
    return result.data
  } catch (error) {
    if(error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    console.log("error", error);
    return null
  }
}

export const getTreeChartData = (systemId, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  let urlString = `/api/or/getTreeData?userId=${userId}`;
  if(systemId){
    urlString += `&systemId=${systemId}`;
  }

  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

export const getORDashboardData = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const urlString = `/api/or/progress-by-category?userId=${userId}`;
  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

import "./Home.scss";

import { Col, Row } from "antd";

import NormalLoginForm from "./Login";
import React from "react";
import SearchHeader from "components/SearchHeader/SearchHeader";
import frontImg from "assets/images/robo-main.gif"

function Login() {

  return (
    <div className="login-page">
      <div>
        <SearchHeader hideSearch hideVersion />
      </div>
      <div>
        <Row>
          <Col span={10} className="left-sidebar">
            <div className="left-margin">
              <NormalLoginForm />
            </div>
          </Col>
          <Col span={14}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${frontImg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center"
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Login;

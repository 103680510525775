import './ForgetPassword.scss';
import './ConfirmPassword.scss';

import { Button, Card, Form, Input } from 'antd';
import {
	emailValidationMessage,
	spacevalidationMessage,
} from 'constants/formValidationRules';
import { useLocation, useNavigate } from 'react-router-dom';

import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import authService from '../../services/auth.service';
import notifyPopup from 'helpers/notificationHelper';
import { trimValue } from 'helpers/utils';

const ForgetPassword = () => {
	const [form] = Form.useForm();
	const { state } = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!state) {
			navigate('/');
		}
	}, [state]);

	if (!state) {
		return null;
	}

	const { forgetPwdFlag } = state; // Read values passed on state

	React.useEffect(() => {
		if (forgetPwdFlag === false) {
			navigate('/');
		}
	}, [forgetPwdFlag]);

	if (forgetPwdFlag === false) {
		return null;
	}

	const onSubmit = async () => {
		try {
			const formValues = await form.validateFields();

			Object.keys(formValues).keys(
				(key) => (formValues[key] = trimValue(formValues[key]))
			);

			const result = await authService.forgetPassword({
				userName: formValues.email,
			});
			if (result && result.status && result.status === 200) {
				navigate('/user/confirmPassword', {
					state: { resetPwdFlag: true, userName: formValues.email },
				});
			}
		} catch (error) {
			console.log('forget pwd api call:', error.error); //send in notification
			notifyPopup(
				'error',
				'Notification',
				error.error ?? 'Something went wrong'
			);
		}
	};

	React.useEffect(function () {
		authService.me();
	}, []);

	const ForgetPasswordSection = () => {
		return (
			<div className='welcome-page'>
				<div className='vertical-center'>
					<Card
						className='forget-password-info-bold'
						title='Forget/Reset Password'
						bordered={false}
						style={{ width: 500 }}
					>
						<p className='forget-password-info'>
							Don't worry! Enter the email address associated with your account
							and you will receive an OTP for reset your password.
						</p>
						<Form layout='vertical' form={form}>
							<Form.Item
								className='forget-password-info-bold'
								label='Email'
								name='email'
								rules={[
									{
										required: true,
										message: emailValidationMessage,
									},
									{
										whitespace: true,
										message: spacevalidationMessage,
									},
								]}
							>
								<Input
									placeholder=''
									prefix={<UserOutlined className='site-form-item-icon' />}
								/>
							</Form.Item>
							<Form.Item style={{ float: 'right' }}></Form.Item>
							<Button
								type='primary'
								htmlType='submit'
								className='login-form-button'
								style={{ background: 'rgba(0, 0, 0, 0.8)' }}
								onClick={onSubmit}
							>
								Continue
							</Button>
						</Form>
					</Card>
				</div>
			</div>
		);
	};

	return <ForgetPasswordSection />;
};

export default ForgetPassword;

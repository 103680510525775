import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import UserService from "services/admin/user.service";
import { setMessage } from "../message";
import { useDispatch } from "react-redux";

export const addUser = createAsyncThunk(
  "user/add",
  async (formData, thunkAPI) => {
    const { data: values, dispatch } = formData
    try {
      const data = await UserService.addUser(values, dispatch);
      return { status: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/edit",
  async (formData, thunkAPI) => {
    const { data: values, dispatch } = formData
    try {
      const data = await UserService.editUser(values, dispatch);
      return { status: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "user/getUserDetail",
  async (formData, thunkAPI) => {
    const { data: values, dispatch } = formData
    try {
      const data = await UserService.getUserDetail(values, dispatch);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const listUser = createAsyncThunk(
  "user/list",
  async (formData, thunkAPI) => {
    try {
      const { userData: values, dispatch } = formData;
      const data = await UserService.listUser(values, dispatch);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// pending
export const listUserRoles = createAsyncThunk(
  "user/listroles",
  async (formData, thunkAPI) => {
    try {
      const data = await UserService.listUserRoles();
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const listUserProjects = createAsyncThunk(
  "user/listprojects",
  async (formData, thunkAPI) => {
    const dispatch = useDispatch();
    try {
      const data = await UserService.listUserProjects(dispatch);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  isUserAdded: false,
  usersList: [],
  usersList_metadata: {},
  rolesList: [],
  projectsList: [],
  userDetail: {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [addUser.fulfilled]: (state) => {
      state.isUserAdded = true;
    },
    [addUser.rejected]: (state) => {
      state.isUserAdded = false;
    },
    [listUser.fulfilled]: (state, action) => {
      state.usersList = action.payload.data.records.records;
      state.usersList_metadata = action.payload.data.records._metadata;
    },
    [listUserRoles.fulfilled]: (state, action) => {
      state.rolesList = action.payload.data.records;
    },
    [listUserProjects.fulfilled]: (state, action) => {
      state.projectsList = action.payload.data.records;
    },
    [getUserDetail.fulfilled]: (state, action) => {
      state.userDetail = action.payload.data.records;
    },
    [getUserDetail.rejected]: (state) => {
      state.userDetail = {}
    },
  },
});
const { reducer } = userSlice;
export default reducer;
import './modal.scss';

import { ActionButton, FormSubmitBtn } from 'components/button/Button';

import { Modal } from 'antd';
import React from 'react';

export default function ToggleModal({ children, visible, onClose }) {
	return (
		<Modal
			onCancel={onClose}
			visible={visible}
			destroyOnClose
			footer={null}
			centered
			closable
		>
			{children}
		</Modal>
	);
}

export function CloseTask({ selectedTask, onSuccess, onCancel, successText }) {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<h1
				style={{
					fontFamily: 'Nunito',
					fontSize: '20px',
					marginBottom: '40px',
					marginTop: '20px',
					textAlign: 'center',
				}}
			>
				Are you sure you want to mark {selectedTask.taskId} as complete?
			</h1>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<ActionButton
					style={{ marginRight: '12px' }}
					text={successText}
					onMarkCompleteChange={onSuccess}
				/>
				<button
					style={{
						display: 'flex',
						alignItems: 'center',
						fontFamily: 'Nunito',
						fontSize: '14px',
						background: 'black',
						color: 'white',
						padding: '8px 15px',
						border: 0,
						borderRadius: '4px',
						cursor: 'pointer',
					}}
					onClick={onCancel}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}

export const DeleteTags = ({ onSuccess, onCancel, isTag = false }) => {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<h3 className='close-info'>
				System / Sub-system links to this Tag {isTag ? '' : 'Group'} will be
				permanently deleted. This action cannot be undone.
			</h3>
			<div
				style={{
					width: '80%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<FormSubmitBtn text={'Cancel'} onSubmit={onCancel} />
				<FormSubmitBtn text='Continue' onSubmit={onSuccess} />
			</div>
		</div>
	);
};

export const UploadModal = ({ onSuccess, onCancel }) => {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<h3 className='close-info'>
				Internal links may be broken if this operation is not done carefully.
				This action cannot be undone.
			</h3>
			<div
				style={{
					width: '80%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<FormSubmitBtn text={'Cancel'} onSubmit={onCancel} />
				<FormSubmitBtn text='Continue' onSubmit={onSuccess} />
			</div>
		</div>
	);
};

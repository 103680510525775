import { CLIENT_ROUTES, ICORA_ROUTES } from "../constants/permission-maps";
import { useEffect, useState } from "react"

import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const checkRoute = (location, paths) => {
  let found = false
  for (const path of paths) {
    const regex = new RegExp(path, 'ig')
    if (location.search(regex) === 0) {
      found = true;
      break;
    }
  }
  return found;
}

export const useAuth = () => {
  const roleName = useSelector((state) => state.auth.role)
  const location = useLocation();

  const [auth, setAuth] = useState(null)

  const init = () => {
    if (
      (roleName === "icorauser" && checkRoute(location.pathname, ICORA_ROUTES)) || (roleName === "client" && checkRoute(location.pathname, CLIENT_ROUTES)) || (roleName === "projectadmin")
    ) {
      setAuth(true)
    } else {
      setAuth(false)
    }
  }

  useEffect(() => {
    init()
  }, [location.pathname])

  return auth
}
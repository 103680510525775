import "./PageHeading.scss";

import { ExportButton, IconButton } from "components/button/Button"
import { Link, useNavigate } from "react-router-dom";
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthCheckDisplay } from "guards/AuthProvider"
import closeIcon from "assets/icons/close.svg";
import { Tooltip } from "antd";
import HelpIconToolTip from "components/HelpToolTip/HelpIconToolTip";

function PageHeadingMultiButtons({
  title,
  closeLink = "",
  buttonPosition = "flex-end",
  customButtonPosition = "flex-end",
  buttons,
  breadCrumb = [],
  customButtonAction = "",
  desc = "",
  marginBottom = "40px",
  canExport = false,
  titleWidth = "100%",
  subTitle = false,
  breadCrumbWhiteSpace = "normal",
  helpIconTitle = ""
}) {
  const navigate = useNavigate()
  const getTitleWidth = () => {
    if (titleWidth === "100%") {
      return {
        width: "fit-content",
        maxWidth: "100%",
        minWidth: "50%"
      }
    } else {
      return {
        width: titleWidth,
      }
    }
  }
  return (
    <div
      className="page-head-section"
      style={{
        marginBottom,
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <div style={{ width: "100%", display: "flex" }}>
        <div style={getTitleWidth()} className="page-heading">
          <div className="page-heading-wrapper">
            {breadCrumb.length ? (
              <>
                {breadCrumb.map(val => (
                  <>
                    <div className="breadcrumb-link-container">
                      <Link
                        className="breadcrumb-link"
                        to={val.breadCrumbLink}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {val.breadCrumbText}
                      </Link>
                    </div>
                    <span className="breadcrumb-page">
                      {" > "}
                    </span>
                    <span style={{ whiteSpace: breadCrumbWhiteSpace }} className="breadcrumb-page">
                      {val.breadCrumbPageText}
                    </span>
                  </>
                ))}
              </>
            ) : (
              <span style={{ maxWidth: "100%", fontSize: subTitle ? "24px" : "36px" }}>
                {title ? title : ""}
              </span>
            )}
          </div>
          {desc && (
            <div className="page-heading-desc">
              <span>{desc}</span>
              {/* Help icon tooltip */}
              {helpIconTitle &&
              <HelpIconToolTip helpIconTitle={helpIconTitle}/> 
              }
            </div>
          )}
        </div>
        {(buttons && buttons.length) || customButtonAction ? (
          <div style={{ width: "50%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: buttonPosition
              }}
            >
              {buttons && (
                buttons.map((btn, i) => (
                  <div className="page-heading-action" key={i}>
                    <IconButton
                      onClick={() => btn.buttonLink ? navigate(btn.buttonLink) : btn.onClick()}
                      link={
                        <Link to={btn.buttonLink ?? "#"}>
                          {btn.buttonText}
                        </Link>}
                      icon={btn.icon}
                    />
                  </div>
                ))
              )}

              {canExport && (
                <AuthCheckDisplay>
                  <div className="page-heading-action">
                    <ExportButton title={title} />
                  </div>
                </AuthCheckDisplay>
              )}
            </div>
            {customButtonAction && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: customButtonPosition
                }}
              >
                {customButtonAction}
              </div>
            )}
          </div>
        ) : null}
      </div>

      {closeLink && (
        <div style={{ maxWidth: "100%" }}>
          <div className="page-heading-action">
            <Link to={closeLink}>
              <img src={closeIcon}></img>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default PageHeadingMultiButtons;

import "./Login.scss";

import { Button, Form, } from "antd";
import React, { useEffect } from "react";
import { emailValidationMessage, neccessaryValidationMessage, spacevalidationMessage } from "constants/formValidationRules"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { async } from "q";
import authService from "services/auth.service"
import { clearMessage } from "store/slices/message";
import { getProjectDetail } from "store/slices/admin/projects";
import { login } from "store/slices/auth";
import { onLoggingIn } from "helpers/utils";

const NormalLoginForm = () => {
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.message);
  const urlParams = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  const handleLogin = async (formValue) => {
    sessionStorage.removeItem("project");
    localStorage.removeItem("project");
    await authService.me()
    onLoggingIn();
    dispatch(
      login({
        username: formValue.username,
        password: formValue.password,
      })
    )
      .unwrap()
      .then(() => {
        sessionStorage.setItem("loginType", urlParams.type);
        dispatch(getProjectDetail())
          .unwrap()
          .then((projectData) => {
            localStorage.setItem(
              "project",
              JSON.stringify(projectData.data.records)
            );
            sessionStorage.setItem(
              "project",
              JSON.stringify(projectData.data.records)
            );
            navigate("/project");
          })
          .catch((e) => console.log(e));

        navigate("/project");
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const onFinish = (values) => {
    handleLogin(values);
  };

  const forgetPasswordOnClick = () => {
    navigate('/user/forgetPassword', {
      state: { forgetPwdFlag: true }
    });
  };

  return (
    <div style={{ marginTop: 0 }} className="heading-top login-component">
      <Form
        name="normal_login"
        layout="vertical"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Email Address"
          name="username"
          rules={[
            {
              required: true,
              message: emailValidationMessage,
            },
            {
              whitespace: true,
              message: spacevalidationMessage
            }
          ]}
        >
          <input type="text" name="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: neccessaryValidationMessage,
            },
            {
              whitespace: true,
              message: spacevalidationMessage
            }
          ]}
        >
          <input type="password" name="password" />
        </Form.Item>

        <Form.Item name="mfa" label="2FA Code(if Enabled)">
          <input type="text" name="mfa" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="primary-button"
            style={{ width: "100%" }}
          >
            Log in
          </Button>
        </Form.Item>
        <p onClick={forgetPasswordOnClick} >Forget/Reset Password?</p>
      </Form>

      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </div>
  );
};

export default NormalLoginForm;

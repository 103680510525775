import './button.scss';

import * as XLSX from 'xlsx';

import { Button, Checkbox, DatePicker, Radio } from 'antd';
import { DateFormat, formatDate } from 'helpers/utils';
import { faCheck, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import {useDispatch} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fileService from 'services/projectmgmt/file.service';
import moment from 'moment';
import notifyPopup from 'helpers/notificationHelper';
import store from 'store';

export const ActionButton = ({ onMarkCompleteChange, text, style = {} }) => {
	return (
		<button
			style={{
				display: 'flex',
				alignItems: 'center',
				fontFamily: 'Nunito',
				fontSize: '14px',
				border: '1px solid rgba(0, 0, 0, 0.8)',
				background: 'white',
				padding: '8px 15px',
				borderRadius: '4px',
				cursor: 'pointer',
				...style,
			}}
			onClick={() => onMarkCompleteChange()}
		>
			<FontAwesomeIcon style={{ marginRight: '8px' }} icon={faCheck} />
			{text}
		</button>
	);
};

export const ActionRoundButton = ({ text }) => {
	return <button className={'round-button'}>{text}</button>;
};

export const IconButton = ({ text, icon, link, onClick, disable = false }) => {
	return (
		<button
			disabled={disable}
			className={'icon-button'}
			onClick={() => onClick()}
		>
			{icon && <img src={icon} />}
			{link ? link : text}
		</button>
	);
};

export const ActionRoundCheckBoxButton = ({
	updateStatus,
	text,
	isActive,
	applyHover = true,
	invertColor = false,
}) => {
	const [textValue, setTextValue] = useState(text);

	const hoverEffect = (action) => {
		if (isActive) {
			if (action === 'enter') {
				setTextValue('De-active');
			} else {
				setTextValue(text);
			}
		} else {
			setTextValue('De-activated');
		}
	};

	useEffect(() => {
		setTextValue(text);
	}, [text]);

	return (
		<button
			className={isActive ? 'round-active-button' : 'round-deactive-button'}
			onClick={() => updateStatus()}
			onMouseEnter={applyHover ? () => hoverEffect('enter') : undefined}
			onMouseLeave={applyHover ? () => hoverEffect('leave') : undefined}
			style={{
				color: invertColor ? 'black' : 'red',
				position: 'relative',
				width: invertColor ? 'auto' : '123px',
				minWidth: '123px',
				padding: invertColor ? '0 12px' : 0,
			}}
		>
			{textValue}
			<div
				style={{
					width: '17px',
					height: '17px',
					background: 'white',
					border: '1px solid black',
					borderRadius: '2px',
					boxSizing: 'border-box',
					padding: '3px',
					marginLeft: '11px',
				}}
			>
				{isActive ? (
					''
				) : (
					<div
						style={{
							width: '100%',
							height: '100%',
							background: invertColor ? 'blue' : 'red',
						}}
					></div>
				)}
			</div>
		</button>
	);
};

export const AttachmentBtn = ({ disabled = false }) => {
	return (
		<>
			{!disabled && (
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						fontFamily: 'Nunito',
						fontSize: '14px',
						cursor: 'pointer',
					}}
				>
					<FontAwesomeIcon style={{ marginRight: '8px' }} icon={faPaperclip} />
					Add New Attachment
				</span>
			)}
		</>
	);
};

export const FormSubmitBtn = ({ onSubmit, text, float = 'right' }) => {
	return (
		<Button
			type='primary'
			style={{
				background: 'rgba(0, 0, 0, 0.8)',
				float,
				padding: '14px 45px',
				boxSizing: 'border-box',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontFamily: 'Nunito',
				fontSize: '14px',
				height: '45px',
			}}
			htmlType='submit'
			onClick={onSubmit}
		>
			{text}
		</Button>
	);
};

export function useOutsideAlerter(ref, cb) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */

		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				cb();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

export const ExportButton = ({ title }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [dataSelected, setDataSelected] = useState([]);
	const [isActive, setIsActive] = useState(null);
	const [isCompleted, setIsCompleted] = useState(null);
	const [addUser, setAddUser] = useState(false);

	const roleName = store.getState().auth.role;

	const buttonRef = useRef(null);
	const tempRef = useRef(null);
	const dispatch = useDispatch();

	const resetData = useCallback(() => {
		setShowMenu(false);
		setStartDate('');
		setEndDate('');
		setDataSelected([]);
		setIsActive(null);
		setIsCompleted(null);
		setAddUser(false);
	}, []);

	useOutsideAlerter(buttonRef, () => resetData());

	const onStartDateChange = (e) => {
		buttonRef.current = document.getElementById('export-container');
		setStartDate(formatDate(e, false, true).format('YYYY-MM-DD'));
	};

	const onEndDateChange = (e) => {
		buttonRef.current = document.getElementById('export-container');
		setEndDate(formatDate(e, false, true).format('YYYY-MM-DD'));
	};

	const onChange = (e, type) => {
		if (Array.isArray(e)) {
			setDataSelected(e);
		} else if (type === 'user') {
			if (e.target.name === 'allUser' && e.target.checked) {
				setAddUser(true);
				setIsActive(null);
			} else if (e.target.name === 'allUser' && !e.target.checked) {
				setAddUser(false);
			} else if (
				e.target.name !== 'allUser' &&
				(!addUser || isActive !== null)
			) {
				setAddUser(true);
				setIsActive(e.target.value);
			}
		} else if (type === 'completed') {
			setIsCompleted(e.target.value);
		}
	};

	const onSubmit = async () => {
		const data = { isActive, isCompleted, startDate, endDate };
		if (addUser) {
			data['type'] = dataSelected.length
				? `${dataSelected.join(',')},user`
				: 'user';
		} else {
			data['type'] = `${dataSelected.join(',')}`;
		}

		if ((startDate && endDate === '') || (endDate && startDate === '')) {
			notifyPopup('error', 'Please Select Both Dates', '');
			return;
		}

		if (data['type'] && data['type'] !== '') {
			const jsonData = await fileService.exportData(data, dispatch);

			const workbook = XLSX.utils.book_new();

			Object.keys(jsonData.records).forEach((heading) => {
				if (jsonData.records[heading].length) {
					const worksheet = XLSX.utils.json_to_sheet(jsonData.records[heading]);
					XLSX.utils.book_append_sheet(workbook, worksheet, heading);
				}
			});

			XLSX.writeFile(workbook, 'exports.xlsx');
			resetData();
		} else {
			notifyPopup('error', 'Please Select the Mandatory Fields', '');
		}
	};

	const dataOptions = [];
	switch (title) {
		case 'Tasks':
			dataOptions.push({ label: 'Task', value: 'task' });
			break;
		case 'Risks':
			dataOptions.push(
				{ label: 'Risk', value: 'risk' },
				{ label: 'Task', value: 'task' }
			);
			break;
		case 'Delays':
			dataOptions.push(
				{ label: 'Task', value: 'task' },
				{ label: 'Delay', value: 'delay' }
			);
			break;
		default:
			dataOptions.push(
				{ label: 'Task', value: 'task' },
				{ label: 'Delay', value: 'delay' },
				{ label: 'Risk', value: 'risk' }
			);
	}

	useEffect(() => {
		resetData();
	}, [window.location.pathname, resetData]);

	return (
		<div
			ref={buttonRef}
			id='export-container'
			className='export-container'
			style={{ position: 'relative' }}
		>
			<button
				style={{
					color: 'white',
					cursor: 'pointer',
				}}
				onClick={() => setShowMenu(!showMenu)}
			>
				Export
			</button>
			{showMenu && (
				<div
					style={{
						position: 'absolute',
						top: '110%',
						left: '-140px',
						width: '300px',
						height: 'auto',
						border: '1px solid black',
						background: 'white',
						zIndex: 999,
					}}
				>
					{roleName !== 'icorauser' && title === 'Users' && (
						<div
							style={{
								boxSizing: 'border-box',
								padding: '8px',
								borderBottom: '2px solid black',
							}}
						>
							<div className='checkbox-group'>
								<Checkbox
									ref={tempRef}
									name='allUser'
									onChange={(e) => onChange(e, 'user')}
								>
									All Users
								</Checkbox>
								<Radio.Group
									onChange={(e) => onChange(e, 'user')}
									value={isActive}
								>
									<Radio name='active' value={true}>
										Active Users
									</Radio>
									<Radio name='inActive' value={false}>
										Inactive Users
									</Radio>
								</Radio.Group>
							</div>
						</div>
					)}
					{title !== 'Users' && (
						<div
							style={{
								boxSizing: 'border-box',
								padding: '8px',
								borderBottom: '2px solid black',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '12px',
								}}
							>
								<div style={{ flex: 1, marginRight: '8px' }}>
									<Checkbox.Group onChange={onChange} options={dataOptions} />
								</div>
								{dataSelected.length !== 0 && (
									<div style={{ flex: 1 }}>
										<Radio.Group
											onChange={(e) => onChange(e, 'completed')}
											value={isCompleted}
										>
											<Radio name='all' value={null}>
												All
											</Radio>
											<Radio name='open' value={false}>
												Open
											</Radio>
											<Radio name='closed' value={true}>
												Closed
											</Radio>
										</Radio.Group>
									</div>
								)}
							</div>
						</div>
					)}
					<div
						style={{
							boxSizing: 'border-box',
							padding: '8px',
						}}
						className='checkbox-group'
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								marginBottom: '12px',
							}}
						>
							<div style={{ flex: 1, marginRight: '8px' }}>
								<DatePicker
									format={DateFormat}
									placeholder='Start Date'
									onChange={onStartDateChange}
									size='medium'
									onClick={() => {
										buttonRef.current = null;
									}}
								/>
							</div>
							<div style={{ flex: 1 }}>
								<DatePicker
									format={DateFormat}
									placeholder='End Date'
									onChange={onEndDateChange}
									size='medium'
									onClick={() => {
										buttonRef.current = null;
									}}
									disabledDate={(current) => {
										if (startDate) {
											const customDate = startDate;
											const futureDate = moment(startDate).add(3, 'months');
											return (
												current < moment(customDate, 'YYYY-MM-DD') ||
												current > futureDate
											);
										}
										return true;
									}}
								/>
							</div>
						</div>
						<div
							style={{
								marginTop: '10px',
								display: 'flex',
							}}
						>
							<div style={{ flex: 1, marginRight: '8px' }}>
								<FormSubmitBtn onSubmit={onSubmit} text={'ok'} />
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

import './WelcomePage.scss';
import './ConfirmPassword.scss';

import { Button, Card, Form, Input } from 'antd';
import { faKey, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
	neccessaryValidationMessage,
	spacevalidationMessage,
} from 'constants/formValidationRules';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LockOutlined } from '@ant-design/icons';
import React from 'react';
import authService from '../../services/auth.service';
import notifyPopup from 'helpers/notificationHelper';
import { trimValue } from 'helpers/utils';

const passwordRequirements = [
	'MUST contain atleast 8 characters (12+ recommended)',
	'MUST contain atleast one uppercase letter',
	'MUST contain atleast one lowercase letter',
	'MUST contain atleast one number',
	'MUST contain atleast one special character',
];

const ConfirmPassword = () => {
	const [form] = Form.useForm();
	const { state } = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!state) {
			navigate('/');
		}
	}, [state]);

	if (!state) {
		return null;
	}

	const { resetPwdFlag, userName } = state; // Read values passed on state

	React.useEffect(() => {
		if (resetPwdFlag === false) {
			navigate('/');
		}
	}, [resetPwdFlag]);

	if (resetPwdFlag === false) {
		return null;
	}

	const onSubmit = async () => {
		const formValues = await form.validateFields();

		Object.keys(formValues).forEach(
			(key) => (formValues[key] = trimValue(formValues[key]))
		);

		if (formValues.new_password !== formValues.confirm_password) {
			notifyPopup('error', 'Notification', 'Password are not same');
			return;
		} else if (
			formValues.confirm_password.match(
				/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
			) === null
		) {
			notifyPopup(
				'error',
				'Notification',
				'Password Should meet all above criteria'
			);
			return;
		}
		try {
			const data = {
				userName,
				password: formValues.confirm_password,
				verificationCode: formValues.otp_code,
			};

			await authService.confirmPassword(data);
			notifyPopup('success', 'Notification', 'Password Reset Successfully');
			navigate('/');
		} catch (error) {
			console.log('reset pwd api call:', error.error); //send in notification
			notifyPopup(
				'error',
				'Notification',
				error.error ?? 'Something went wrong'
			);
		}
	};

	React.useEffect(function () {
		authService.me();
	}, []);

	const ConfirmPasswordSection = () => {
		return (
			<div className='welcome-page'>
				<div className='vertical-center'>
					<Card title='Set Password' bordered={false} style={{ width: 500 }}>
						<h4 className='confirm-pass-info'>Password Requirements</h4>
						<div style={{ marginBottom: '16px' }}>
							{passwordRequirements.map((val, i) => (
								<div key={i} style={{ display: 'flex' }}>
									<FontAwesomeIcon color='red' icon={faXmark} />
									<h6 className='confirm-pass-req-value'>{val}</h6>
								</div>
							))}
						</div>

						<Form layout='vertical' form={form}>
							<Form.Item
								label='New Password'
								name='new_password'
								rules={[
									{
										required: true,
										message: neccessaryValidationMessage,
									},
									{
										whitespace: true,
										message: spacevalidationMessage,
									},
								]}
							>
								<Input
									placeholder=''
									type={'password'}
									prefix={<LockOutlined className='site-form-item-icon' />}
								/>
							</Form.Item>
							<Form.Item
								label='Confirm Password'
								name='confirm_password'
								rules={[
									{
										required: true,
										message: neccessaryValidationMessage,
									},
									{
										whitespace: true,
										message: spacevalidationMessage,
									},
								]}
							>
								<Input
									placeholder=''
									type={'password'}
									prefix={<LockOutlined className='site-form-item-icon' />}
								/>
							</Form.Item>
							<Form.Item
								label='OTP'
								name='otp_code'
								rules={[
									{
										required: true,
										message: neccessaryValidationMessage,
									},
									{
										whitespace: true,
										message: spacevalidationMessage,
									},
								]}
							>
								<Input
									prefix={<FontAwesomeIcon icon={faKey} />}
									placeholder=''
								/>
							</Form.Item>
							<Form.Item style={{ float: 'right' }}></Form.Item>
							<Button
								type='primary'
								htmlType='submit'
								className='login-form-button'
								style={{ background: 'rgba(0, 0, 0, 0.8)' }}
								onClick={onSubmit}
							>
								Save Password
							</Button>
						</Form>
					</Card>
				</div>
			</div>
		);
	};

	return <ConfirmPasswordSection />;
};

export default ConfirmPassword;

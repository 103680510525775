import { Navigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { lastUsedTabInfo } from "helpers/utils"
import { logout } from 'store/slices/auth';
import { useAuth } from "hooks/auth"
import { useEffect } from "react"

export const AuthCheckDisplay = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  if (user.roleName === "client") {
    return null
  }
  return children;
};

export const actionBeforeLogout = (dispatch, logout) => {
  dispatch(logout());
  Cookies.remove('uSER-TOKEN', { path: '' });
  window.sessionStorage.clear();
  window.localStorage.clear()
  lastUsedTabInfo({ user: null }, false)
}

export const isClient = () => {
  const user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : { roleName: "" }

  return user && user.roleName === "client"
}

export const VerifyToken = () => {
  const userToken = Cookies.get("USER-TOKEN") || ""
  const tabData = JSON.parse(localStorage.getItem('tabInfo') || '{}');
  const dispatch = useDispatch();
  const location = useLocation()
  const excludedPaths = ['/', '/logout'];
  const check = () => {
    if (userToken && (tabData.isLoggedIn === true || tabData.isLoggedIn === undefined)) {
      const date = new Date();
      const decodedToken = jwtDecode(userToken)

      const userSessionData = {
        userId: decodedToken.userId,
        username: decodedToken.username,
        roleId: decodedToken.roleId,
        roleName: decodedToken.roleName,
        projectID: decodedToken.projectID,
        email: decodedToken.email,
        token: decodedToken.token
      }
      if (decodedToken.expiry < date.getTime()) {
        actionBeforeLogout(dispatch, logout)
        return <Navigate to='/' />;
      }

      if (!excludedPaths.includes(location.pathname)) {
        localStorage.setItem("user", JSON.stringify(userSessionData))
        sessionStorage.setItem("user", JSON.stringify(userSessionData))
      }
    } else {
      actionBeforeLogout(dispatch, logout)
      return null
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    check()
  }, [location.pathname])

  return null
}

export const AuthProvider = ({ children }) => {
  const auth = useAuth();
  const dispatch = useDispatch();

  if (auth !== null && !auth) {
    actionBeforeLogout(dispatch, logout)
    return <Navigate to='/' />
  } else if (auth !== null && auth) {
    return children
  }

  return null
}
import React from 'react';

export default function ImportTimeline({ timelineStatus }) {
	const {
		fu_inprogress = 'grey',
		fu_done = 'grey',
		fp_inprogress = 'grey',
		fp_done = 'grey',
		fp_status = 'done/failed',
	} = timelineStatus;
	return (
		<div className='import-timeline-container'>
			<div className='line'>
				<div className='line-items'>
					<div
						className={`line-items-circle ${fu_inprogress}`}
						style={{ transform: 'none', left: '0%' }}
					/>
					<p className='line-items-title'>File upload In-Progress</p>
				</div>
				<div className='line-items'>
					<div className={`line-items-circle ${fu_done}`} />
					<p className='line-items-title'>File upload done</p>
				</div>
				<div className='line-items'>
					<div className={`line-items-circle ${fp_inprogress}`} />
					<p className='line-items-title'>File processing In-Progress</p>
				</div>
				<div className='line-items'>
					<div
						className={`line-items-circle ${fp_done}`}
						style={{ transform: 'none', left: 'auto', right: '0%' }}
					/>
					<p className='line-items-title'>File processing {fp_status}</p>
				</div>
			</div>
		</div>
	);
}

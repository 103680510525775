import './WelcomePage.scss';
import './ConfirmPassword.scss';

import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from 'components/loader/Loader';
import authService from '../../services/auth.service';
import notifyPopup from 'helpers/notificationHelper';

const ResetPassword = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [loaded, setLoaded] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const reqObj = {};
	for (const entry of searchParams.entries()) {
		reqObj[entry[0]] = entry[1];
	}
	if (reqObj.code && reqObj.exp) {
		reqObj.code = parseInt(reqObj.code);
		reqObj.exp = parseInt(reqObj.exp); // validation email link expiry date
	}
	const onClickEvent = async () => {
		try {
			setDisabled(true);
			const result = await authService.resetPassword(reqObj);
			if (result && result.records && result.records.userName) {
				navigate('/user/confirmPassword', {
					state: { resetPwdFlag: true, userName: result.records.userName },
				});
			}
		} catch (error) {
			console.log('reset pwd api call:', error.error); //send in notification
			notifyPopup(
				'error',
				'Notification',
				error.error ?? 'Something went wrong'
			);
		}
	};

	const callMe = async () => {
		setLoaded(false);
		await authService.me();
		setLoaded(true);
	};

	React.useEffect(function () {
		callMe();
	}, []);

	const ResetPasswordSection = () => {
		return (
			<div className='welcome-page'>
				{loaded ? (
					<div className='vertical-center'>
						<Card
							title='To set a new password'
							bordered={false}
							style={{ width: 500 }}
						>
							<p>
								Heyyyy! Signing in first time? make sure you are setting your
								password by clicking on Set Password button. Kindly check your
								mailbox for 6 digit code after clicking on Set Password button.
							</p>
							<Button
								onClick={onClickEvent}
								type='primary'
								className='login-form-button'
								style={{ background: 'rgba(0, 0, 0, 0.8)' }}
								disabled={disabled}
							>
								Set Password
							</Button>
						</Card>
					</div>
				) : (
					<Loader />
				)}
			</div>
		);
	};

	return <ResetPasswordSection />;
};

export default ResetPassword;

import React from 'react';

export default function Loader() {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				width: '100%',
			}}
		>
			<div className='lds-facebook'>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

import { Col, Layout, Row, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SearchHeader from "components/SearchHeader/SearchHeader";
import SideBar from "components/SideBar/SideBar";
import dummyIcon from "assets/icons/dummy.png"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"
import fileService from "services/projectmgmt/file.service";
import folderIcon from "assets/icons/folder.svg";
import homeIcon from "assets/icons/home.svg";
import store from "store"

function HomeLayout({ children }) {
  const [logo, setLogo] = useState(dummyIcon)
  const roleName = store.getState().auth.role
  const dispatch = useDispatch();
  const storeLogoName = useSelector((state) => state.project.projectDetail.logo?.logo)
  let logoName = storeLogoName
  if (sessionStorage.getItem("project") && JSON.parse(sessionStorage.getItem("project")) && JSON.parse(sessionStorage.getItem("project")).logo) {
    logoName = JSON.parse(sessionStorage.getItem("project")).logo.logo
  }

  const SkeletonUI = (
    <>
      <div style={{ backgroundColor: "#F2F2F2", height: "100vh" }}>
        <Skeleton style={{ paddingTop: "24px", paddingLeft: "24px" }} />
        <Skeleton style={{ paddingLeft: "24px" }} />
      </div>
    </>
  );

  const getProjectLogo = useCallback(async (projectLogoName) => {
    if (projectLogoName && projectLogoName !== "default" && projectLogoName !== "Default") {
      try {
        const logoUrl = await fileService.getFileUrl('project-logo/' + projectLogoName, 'get', "", dispatch)
        setLogo(logoUrl.message)
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [])

  const bodyTemplate = children ? children : SkeletonUI;

  const menuData = [
    {
      type: "primary",
      icon: homeIcon,
      anchorPath: "/project",
      anchorTitle: "Home",
    },
    {
      type: "sub",
      icon: folderIcon,
      title: "All Data",
      child: [
        {
          type: "primary",
          anchorPath: "/project/alldata/delays",
          anchorTitle: "Delays",
        },
        {
          type: "primary",
          anchorPath: "/project/alldata/risks",
          anchorTitle: "Risks",
        },
        {
          type: "primary",
          anchorPath: "/project/alldata/tasks",
          anchorTitle: "Tasks",
        },
      ],
    },
  ];

  if (roleName === "icorauser") {
    menuData.push({
      type: "primary",
      icon: <FontAwesomeIcon color="white" icon={faChartLine} />,
      anchorPath: "/clientDashboard",
      anchorTitle: "Dashboard",
      customIcon: true
    })
  }

  useEffect(() => {
    getProjectLogo(logoName)
  }, [getProjectLogo, logoName])

  return (
    <Layout>
      <Layout>
        <SearchHeader />
        <Layout>
          <Row>
            <Col span={4}>
              <SideBar
                menus={children ? menuData : []}
                showProjectBar={children ? menuData : false}
                logo={logo}
              />
            </Col>
            <Col span={20}>
              <div style={{ background: "#F2F2F2", marginBottom: "40px" }}>
                <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                  {bodyTemplate}
                </div>
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default HomeLayout;

import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import axios from "axios";
import createDOMPurify from "dompurify";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const DOMPurify = createDOMPurify(window);

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "-";
}

export function trimValue(value) {
  return typeof value === "string" ? value.trim() : value;
}

export const DateFormat = "DD-MM-YYYY";
export const DateFormatInYYYYMMDD = "YYYY-MM-DD";
export const DateFormatLogs = "DD-MM-YYYY HH:MM:SS";

export const noFiles = "No attachments to display";

export function formatDate(
  dateString,
  logs = false,
  mObj = false,
  localFormat = "MM-DD-YYYY"
) {
  const format = logs ? "MM-DD-YYYY HH:MM:SS" : localFormat;
  const finalDateFormat = logs ? DateFormatLogs : DateFormat;
  const local = logs ? dateString : moment.utc(dateString, format).local();

  if (mObj) {
    return moment(local, finalDateFormat);
  }
  return moment(local, finalDateFormat).format(finalDateFormat);
}

const splitOneChunk = (text) => {
  const charList = [];
  let chunk = "",
    smallWord = "";
  const textArr = text.split("");
  textArr.forEach((e) => {
    if (chunk.length < 20) {
      chunk += e;
    } else {
      charList.push(chunk);
      chunk = "";
    }
  });
  charList.forEach((char, i) => {
    if (i < 2) {
      smallWord += `${char} `;
    }
  });
  smallWord = charList.length > 1 ? `${smallWord}...` : smallWord;
  return <span>{smallWord}</span>;
}

export const splitBigWords = (text) => {
  if (text && text.length > 40 && text.split(" ").length === 1) {
    return splitOneChunk(text)
  } else if (text && text.split(" ").length) {
    const wordList = text.split(" ");
    let len = 0,
      smallWord = "";
    wordList.forEach((e) => {
      if (len + e.length < 20) {
        smallWord += `${e} `;
        len += e.length;
      }
    });
    smallWord = text.length > smallWord.length ? `${smallWord}...` : smallWord;
    return <span>{smallWord}</span>;
  }
};

export const CompletedTag = ({ text }) => {
  return (
    <div
      style={{
        fontFamily: "Nunito",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        padding: "8px 15px",
        borderRadius: "4px",
        height: "100%",
        background: "#f6ffed",
        color: "#56b074",
        border: "1px solid #56b074",
        marginRight: "12px",
      }}
    >
      {text}
    </div>
  );
};

export const DateOneLiner = ({ date, logs = false }) => {
  return (
    <p
      style={{
        width: logs ? "150px" : "80px",
        height: "100%",
        marginBottom: 0,
      }}
    >
      {date ? formatDate(date, logs) : "-"}
    </p>
  );
};

const TooltipText = () => {
  return (
    <ul>
      <li>Please make sure file size should not exceed 20Kb</li>
      <li>Please note logo resolution should be below 18*18.</li>
    </ul>
  );
};

export const UploadTooltip = () => {
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}
    >
      <p style={{ marginBottom: 0, marginRight: "4px" }}>Logo</p>
      <Tooltip placement="rightBottom" title={<TooltipText />}>
        <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCircleInfo} />
      </Tooltip>
    </div>
  );
};

export const calcDuration = (form) => {
  if (
    form.getFieldValue("startdate") &&
    moment(form.getFieldValue("finishdate"))
  ) {
    const days = moment(form.getFieldValue("startdate")).diff(
      moment(form.getFieldValue("finishdate")),
      "days"
    );
    form.setFieldsValue({ days: Math.abs(days) });
  }
};

export const checkResolution = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width <= 36 && img.height <= 36) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
};

export const onLoggingIn = () => {
  const hash = "tab_" + +new Date();
  const tabData = JSON.parse(localStorage.getItem("tabInfo") || "{}");
  if (tabData["activeTab"]) {
    tabData["activeTab"][hash] = true;
  } else {
    tabData["activeTab"] = {
      [hash]: true,
    };
  }
  localStorage.setItem("tabInfo", JSON.stringify(tabData));
};

export const lastUsedTabInfo = (auth, loggedIn = null) => {
  const lastHitUrl = window.location.pathname;
  const userObj = auth.user;
  const tabData = JSON.parse(localStorage.getItem("tabInfo") || "{}");

  let lastUsedTabData = {
    ...tabData,
    lastHitUrl,
    userObj,
    isLoaded: false,
  };
  if (loggedIn === false) {
    lastUsedTabData = {
      activeTab: {},
      isLoggedIn: loggedIn,
    };
  }

  if (loggedIn) {
    localStorage.setItem(
      "tabInfo",
      JSON.stringify({ ...tabData, isLoggedIn: loggedIn })
    );
  } else {
    setTimeout(() => {
      localStorage.setItem("tabInfo", JSON.stringify(lastUsedTabData));
    }, 800);
  }
};

export const API = (uploadProgress) => {
  const csrfToken = Cookies.get("XSRF-TOKEN") || "";

  return axios.create({
    baseURL: process.env.REACT_APP_APIURL,
    responseType: "json",
    headers: {
      "XSRF-TOKEN": csrfToken,
      "Content-Type": "application/json",
    },
    withCredentials: true,
    onUploadProgress: uploadProgress,
  });
};

export const getCurrentFormatedDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;
  return `${dd}-${mm}-${yyyy}`;
};

// common method for prevention of xss attack
export const getSanitizedData = (strData) => {
  const data = DOMPurify.sanitize(strData);
  return data.replace(/(<([^>]+)>)/gi, "").trim();
};

export const customSorter = (a, b, name, order) => {
  if (a[name] === b[name]) {
    return 0;
  }
  //sort null after everything
  if (a[name] === null) {
    return 1;
  }
  if (b[name] === null) {
    return -1;
  }
  // sort in ascending
  if (order.toLowerCase() === "asc") {
    return a[name].toLowerCase() < b[name].toLowerCase() ? -1 : 1;
  }
  // sort in descending
  return a[name].toLowerCase() > b[name].toLowerCase() ? -1 : 1;
};

// Risk Priority labels
export const riskPriorityLabel = {
  Low: 'Low',
  Moderate: 'Possible',
  High: 'High',
  Extreme: 'Extreme',
  Medium: 'Medium'
}

import { API, getSanitizedData } from "helpers/utils";
import { isTokedExpired } from "services/util";

import store from "store";

const addUser = (formData, dispatch = null) => {
  const data = formData;
  const adminUserId = store.getState().auth.user.userId;
  data["adminUserId"] = adminUserId;

  return API().post('/api/user/add-user', data).then((response) => {
    return response.data;
  }).catch(e=>{
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
};

const editUser = (formData, dispatch = null) => {

  const adminUserId = store.getState().auth.user.userId;
  const data = JSON.stringify({
    userName: getSanitizedData(formData.data.username),
    adminUserId: adminUserId,
    userId: formData.id,
    role: formData.data.userrole,
    jobTitle: getSanitizedData(formData.data.jobtitle),
    companyName: getSanitizedData(formData.data.companyname),
    projectAllocated: formData.data.projectallocated
  });

  return API().patch('/api/user/edit', data).then((response) => {
    return response.data;
  }).catch(err => {
    if(err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    }
    throw err.response.data;
  });
};

const listUser = (formData, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/user/get-users?adminUserId=${adminUserId}&page=${formData.page}&sortBy=${formData.sortBy}&order=${formData.order}&page_size=10`

  return API().get(url).then((response) => {
    return response.data;
  }).catch(err=>{
    if(err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    }
    throw err.response.data;
  });
};

const listUserRoles = (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/user/get-roles?adminUserId=" + adminUserId;

  return API().get(url).then((response) => {
    return response.data;
  }).catch(err=>{
    if(err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    }
    throw err.response.data;
  });
};

const listUserProjects = (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/project/get-projects?adminUserId=" + adminUserId;

  return API().get(url).then((response) => {
    return response.data;
  }).catch(err=>{
    if(err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    }
    throw err.response.data;
  });
};

// soft-delete
const deactivateUser = (userID, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const data = {
    "adminUserId": adminUserId,
    "userId": userID
  }

  return API().patch('/api/user/deactivate-user', data).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

// hard-delete
const disableUser = (userID, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const data = {
    "adminUserId": adminUserId,
    "userId": userID
  }

  return API().patch('/api/user/disable-user', data).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const enabelUser = (userID, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const data = {
    "adminUserId": adminUserId,
    "userId": userID
  }

  return API().patch('/api/user/enable-user', data).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const getUserDetail = (userID, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId; // || store.getState().auth.user.userId;
  const url = '/api/user/details?adminUserId=' + adminUserId + '&userId=' + userID;

  return API().get(url).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
}

const getAllLogs = (page, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/logs?adminUserId=${adminUserId}&limit=100&page=${page}`;
  return API().get(url).then((response) => {
    return response.data;
  }).catch(e => {
    if(e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });

}

const userService = {
  addUser,
  editUser,
  listUser,
  listUserRoles,
  listUserProjects,
  deactivateUser,
  disableUser,
  enabelUser,
  getUserDetail,
  getAllLogs
};
export default userService;

import { API, lastUsedTabInfo } from "helpers/utils"

import authHeader from "./auth-header";

const me = async () => {
  const result = await API().get("/me")
  return result.data
}

const login = (username, password) => {
  const data = JSON.stringify({
    userName: username,
    password: password,
  });
  const api = "/api-auth/user/signin";

  return API().post(api, data).then((response) => {
    if (response.data) {
      sessionStorage.setItem("user", JSON.stringify(response.data.records));
      lastUsedTabInfo(response.data.records, true)
    }
    return response.data;
  });
};

const logout = () => {
  if (authHeader().authorization) {
    return API().post('/api-auth/signout', {}, { headers: { authorization: authHeader().authorization } })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return true;
};

const resetPassword = (data) => {
  return API().post('/api-auth/reset-password', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error.response.data;
    });
};

const forgetPassword = (data) => {
  return API().post('/api-auth/forget-password', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error.response.data;
    });
};

const confirmPassword = (data) => {
  return API().post('/api-auth/confirm-password', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error.response.data;
    });
}

const authService = {
  login,
  logout,
  resetPassword,
  forgetPassword,
  confirmPassword,
  me
};
export default authService;

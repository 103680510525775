import { Navigate } from 'react-router-dom';
import { actionBeforeLogout } from 'guards/AuthProvider';
import { logout } from 'store/slices/auth';
import { removeProjectDetail } from 'store/slices/admin/projects';
import { useDispatch } from 'react-redux';

export default function Logout() {
	const dispatch = useDispatch();
	actionBeforeLogout(dispatch, logout);
	dispatch(removeProjectDetail());
	return <Navigate to='/' />;
}

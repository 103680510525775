import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ProjectService from "services/admin/project.service";
import { setMessage } from "../message";

export const addProject = createAsyncThunk(
  "project/add",
  async (formData, thunkAPI) => {
    try {
      const data = await ProjectService.addProject(formData);
      return { status: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editProject = createAsyncThunk(
  "project/edit",
  async (formData, thunkAPI) => {
    try {
      const data = await ProjectService.editProject(formData);
      return { status: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const listProject = createAsyncThunk(
  "project/list",
  async (formData, thunkAPI) => {
    try {
      const data = await ProjectService.listProject(formData);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const listProjectUsers = createAsyncThunk(
  "project/listUsers",
  async (formData, thunkAPI) => {
    try {
      const data = await ProjectService.listProjectUsers(formData);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProjectDetail = createAsyncThunk(
  "project/getProjectDetail",
  async (formData, thunkAPI) => {
    try {
      const data = await ProjectService.getProjectDetail(formData);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeProjectDetail = createAsyncThunk("project/removeProjectDetail", () => {
  return true
})

const initialState = {
  isProjectAdded: false,
  projectsList: [],
  projectsListMetadata: [],
  projectUsersList: [],
  projectUsersListMetadata: {},
  projectDetail: {
    logo: {
      logo: ""
    }
  }
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  extraReducers: {
    [addProject.fulfilled]: (state) => {
      state.isProjectAdded = true;
    },
    [addProject.rejected]: (state) => {
      state.isProjectAdded = false;
    },
    [listProject.fulfilled]: (state, action) => {
      state.projectsList = action.payload.data.records.records;
      state.projectsListMetadata = action.payload.data.records._metadata;
    },
    [listProjectUsers.fulfilled]: (state, action) => {
      state.projectUsersList = action.payload.data.records.records;
      state.projectUsersListMetadata = action.payload.data.records._metadata;
    },
    [listProjectUsers.rejected]: (state) => {
      state.projectUsersList = [];
    },
    [getProjectDetail.fulfilled]: (state, action) => {
      state.projectDetail = action.payload.data.records;
    },
    [removeProjectDetail.fulfilled]: (state) => {
      state.projectDetail = {
        logo: {
          logo: ""
        }
      }
    },
    [getProjectDetail.rejected]: (state) => {
      state.projectDetail = {};
    }
  },
});
const { reducer } = projectSlice;
export default reducer;
import { Form, Upload } from "antd";
import { UploadTooltip, checkResolution } from "./utils"

import { AttachmentBtn } from "components/button/Button"
import React from "react";
import axios from "axios";
import { isClient } from "guards/AuthProvider"
import notifyPopup from "helpers/notificationHelper";

const FileUpload = ({ filePutUrlCb, multiple = true, uploadOnlyImage = false, cb }) => {
  const [fileList, setFileList] = React.useState([]);
  const [fileUploadUrl, setFileUploadUrl] = React.useState("");
  const [hideAfterClick, setHideAfterClick] = React.useState(false)

  const customRequest = ({
    action,
    file,
    onError,
    onSuccess,
    onProgress,
  }) => {
    const fileType = file.type;
    const options = {
      onUploadProgress: (event) => {
        const { loaded, total } = event;
        onProgress(
          {
            percent: Math.round((loaded / total) * 100),
          },
          file
        );
      },
      headers: {
        "Content-Type": fileType,
      },
    };

    axios
      .put(action, file, options)
      .then((result) => {
        onSuccess(result, file)
        setHideAfterClick(false)
      })
      .catch((error) => {
        onError(error);
        alert("ERROR " + JSON.stringify(error));
      });
  };

  const uploadProps = {
    multiple: true,
    maxCount: 10,
    method: "PUT",
    action: fileUploadUrl,
    customRequest: customRequest,
    onChange(info) {
      if (info.file.status === "done") {
        notifyPopup(
          "success",
          "Notification",
          `${info.file.name} file uploaded successfully`
        );
      } else if (info.file.status === "error") {
        notifyPopup(
          "error",
          "Notification",
          `${info.file.name} file upload failed.`
        );
      }
    },
  };

  const addFilesToCB = (filesToCB) => {
    const list = filesToCB
    cb(list)
  }

  const beforeUpload = async (file) => {
    setHideAfterClick(true)
    if (!isClient()) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const fileName = Date.parse(new Date()) + "_" + file.name;
      file["uploadFileName"] = fileName;
      const url = await filePutUrlCb(fileName);
      setFileUploadUrl(url.message);
      if (uploadOnlyImage && isJpgOrPng && file.size >= 1 && file.size <= 20000 && await checkResolution(file)) {
        //first cond
        setFileList([...fileList, file]);
        addFilesToCB([...fileList, file])
        return true
      } else if (uploadOnlyImage && isJpgOrPng) {
        //second cond
        setHideAfterClick(false)
        notifyPopup("error", "Notification", "Please check size or logo resolution")
        return false
      } else if (uploadOnlyImage && !isJpgOrPng) {
        //third cond
        setHideAfterClick(false)
        notifyPopup(
          "error",
          "Notification",
          `upload only image`
        );
        return false
      } else if (!uploadOnlyImage) {
        //fourth cond
        setFileList([...fileList, file]);
        addFilesToCB([...fileList, file])
        return true;
      }
    } else {
      setHideAfterClick(false)
      notifyPopup("error", "Notification", "Action is not allowed for clients")
      return false
    }
  };

  const onRemove = async (file) => {
    if (!isClient()) {
      const newFileList = fileList.filter((item) => item.uid != file.uid);
      setFileList(newFileList);
      setHideAfterClick(false)
      addFilesToCB(newFileList)
    } else {
      notifyPopup("error", "Notification", "Action is not allowed for clients")
      return false
    }
  };

  return (
    <Form.Item name="attachments" label={uploadOnlyImage ? "" : "Attachments"}>
      {uploadOnlyImage && (
        <UploadTooltip />
      )}
      <Upload
        name="attachment"
        {...uploadProps}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        multiple={multiple}
        fileList={fileList}
      >
        <AttachmentBtn disabled={(uploadOnlyImage && fileList.length) || hideAfterClick} />
      </Upload>
    </Form.Item>
  );
};

export default FileUpload;

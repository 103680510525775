// admin section

import authReducer from "./slices/auth";
import { configureStore } from "@reduxjs/toolkit"
import getSearchListReducer from "./slices/search/searchHeader";
import messageReducer from "./slices/message";
import projectReducer from "./slices/admin/projects";
import riskReducer from "./slices/projectmgmt/risk";
import taskReducer from "./slices/projectmgmt/task";
import userReducer from "./slices/admin/user";

// project management

const reducer = {
  auth: authReducer,
  users: userReducer,
  message: messageReducer,
  tasks: taskReducer,
  risks: riskReducer,
  search: getSearchListReducer,
  project: projectReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;

import { notification} from 'antd';

const openNotification = (type, message, description, placement="bottomRight") => {
  notification[type]({
    message: message,
    description: description??'Something went wrong',
    placement,
    duration: 2
  });
};

export default openNotification;
import { actionBeforeLogout } from 'guards/AuthProvider';
import { logout } from 'store/slices/auth';
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

export const getUserAndProjectData = () => {
  const projectId = (store.getState().project.projectDetail.projectId ||
    (sessionStorage.getItem("project") &&
      JSON.parse(sessionStorage.getItem("project")).projectId)) ?? 1;
  const userId = JSON.parse(sessionStorage.getItem("user")).userId;
  return { projectId, userId }
}

export const isTokedExpired = (dispatch, error) => {
  actionBeforeLogout(dispatch, logout);
  notifyPopup('error', 'Notification', error.error);
}
import { Select, Spin } from 'antd';

import React from 'react';
import debounce from 'lodash/debounce';

function DebounceSelect({
	fetchOptions,
	debounceTimeout = 800,
	onChangeProp,
	disabled = false,
	defaultValue = null,
	...props
}) {
	const [fetching, setFetching] = React.useState(false);
	const [options, setOptions] = React.useState([]);
	const [children, setChildren] = React.useState(null);

	const fetchRef = React.useRef(0);
	const debounceFetcher = React.useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			fetchOptions(value).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				if (props.mode === 'multiple') {
					setOptions(newOptions.options);
					setChildren(newOptions.children);
				} else {
					setOptions(newOptions);
				}
				setFetching(false);
			});
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);
	return (
		<Select
			labelInValue
			onChange={(v) => {
				onChangeProp(v);
				setOptions([]);
			}}
			filterOption={false}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size='small' /> : null}
			{...props}
			allowClear
			options={options}
			disabled={disabled}
			defaultValue={defaultValue}
		>
			{props.multiple && children}
		</Select>
	);
}

export default DebounceSelect;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import RiskService from "services/projectmgmt/risk.service";

export const listRisk = createAsyncThunk(
  "risks/list",
  async (formData, thunkAPI) => {
    try {
      const data = await RiskService.listRisk(formData);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getRiskTasks = createAsyncThunk(
  "risks/tasks/list",
  async (formData, thunkAPI) => {
    try {
      const data = await RiskService.getRiskTasks(formData);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


const initialState = {
  risksList: []
};

const riskSlice = createSlice({
  name: "risks",
  initialState,
  extraReducers: {
    [listRisk.fulfilled]: (state, action) => {
      state.risksList = action.payload.data.records.records;
      state.risksList_metadata = action.payload.data.records._metadata;
    },
    [listRisk.rejected]: (state) => {
      state.risksList = [];
    },
    [getRiskTasks.fulfilled]: (state, action) => {
      state.risksTasksList = action.payload.data.records.records;
      state.risksTasksList_metadata = action.payload.data.records._metadata;
    },
    [getRiskTasks.rejected]: (state) => {
      state.risksList = [];
    }
  },
});
const { reducer } = riskSlice;
export default reducer;
import * as XLSX from 'xlsx';

import React, { useEffect, useRef, useState } from 'react';
import ToggleModal, { UploadModal } from 'components/Modal/ToggleModal';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { IconButton } from 'components/button/Button';
import ImportTimeline from './ImportTimeline';
import PageHeadingMultiButtons from 'components/PageHeading/PageHeadingMultiButtons';
import axios from 'axios';
import downloadIcon from 'assets/icons/download.png';
import fileService from 'services/projectmgmt/file.service';
import { getCurrentFormatedDate } from '../../../helpers/utils';
import moment from 'moment';
import notifyPopup from 'helpers/notificationHelper';
import searchIcon from 'assets/icons/search.svg';
import uploadIcon from 'assets/icons/upload.png';
import { useDispatch } from 'react-redux';

export default function ImportFile() {
	const urlParams = useParams();
	const inputRef = useRef(null);
	const lock = useRef(false);
	const intervalId = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [lastUpdated, forceFetch, setForceFetch] = useOutletContext();
	const pollTime = parseInt(process.env.REACT_APP_POLL);
	const pollTimeOut = parseInt(process.env.REACT_APP_POLL_TIMEOUT);

	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [timelineStatus, setTimelineStatus] = useState({
		fu_inprogress: 'grey',
		fu_done: 'grey',
		fp_inprogress: 'grey',
		fp_done: 'grey',
		fp_status: 'done/failed',
	});

	const pageTitle = urlParams.type === 'p6' ? 'P6 Schedule' : 'Tags';
	const pageDate =
		urlParams.type === 'p6'
			? lastUpdated.p6LastUpdate
			: lastUpdated.tagLastUpdate;

	const closeLink = () => {
		return '/admin/imports';
	};

	const handleFileUpload = (e) => {
		const { files } = e.target;
		if (files && files.length && files[0].name.split('.')[1] === 'xlsx') {
			setFile(files[0]);
		} else {
			notifyPopup('error', 'Upload only .xlsx file', '');
		}
	};

	const onClickOpenModal = () => {
		setOpenModal(true);
	};

	const onCancel = () => {
		setOpenModal(false);
	};

	const onClick = () => {
		inputRef.current.click();
	};

	const uploadProgress = (progressEvent) => {
		const { loaded, total } = progressEvent;
		const percent = Math.round((loaded / total) * 100);
		setProgress(percent);
	};

	const uplodToBucket = async (url, uploadfile) => {
		try {
			const result = await axios.put(url, uploadfile, {
				onUploadProgress: uploadProgress,
			});
			return result.data;
		} catch (error) {
			console.log('error', error);
		}
	};

	const sleep = (ms) => {
		return new Promise((resolve) => {
			return setTimeout(() => {
				resolve('ok');
			}, ms);
		});
	};

	const pollProcessingStatus = async (fileName) => {
		if (!lock.current) {
			lock.current = true;
			const result = await fileService.getS3Status(fileName, dispatch);
			lock.current = false;
			if (
				(result && result.records.fileProcessStatus === 'In-progress') ||
				(result && result.records.fileProcessStatus === null)
			) {
				setTimelineStatus((timelineStatus) => ({
					...timelineStatus,
					fp_inprogress: 'green',
				}));
			} else if (
				result &&
				result.records.fileProcessStatus.toLowerCase() === 'complete'
			) {
				setTimelineStatus((timelineStatus) => ({
					...timelineStatus,
					fp_done: 'green',
					fp_status: 'done',
				}));
				setFile(null);
				notifyPopup('success', 'Success', 'File Imported Successfully');
				// if status is completed clear the interval
				clearInterval(intervalId.current);
			} else if (
				result &&
				result.records.fileProcessStatus.toLowerCase() === 'failed'
			) {
				setTimelineStatus((timelineStatus) => ({
					...timelineStatus,
					fp_done: 'red',
					fp_status: 'failed',
				}));
				setFile(null);
				notifyPopup(
					'error',
					'Error',
					'File Imported failed, Please retry after some time'
				);
				// if status is completed clear the interval
				clearInterval(intervalId.current);
			}
		}
	};

	const onSubmit = async () => {
		if (file) {
			const type = urlParams.type === 'p6' ? 'primavera' : 'tags';
			const fileName = Date.parse(new Date()) + '_' + file.name;
			const prefix =
				'datalake/' +
				type +
				'/' +
				getCurrentFormatedDate().toString() +
				'/' +
				fileName;
			try {
				setOpenModal(false);
				// set fu_status to inprogress
				setTimelineStatus((timelineStatus) => ({
					...timelineStatus,
					fu_inprogress: 'green',
				}));

				// sleep
				await sleep(10000);
				const url = await fileService.getFileUrl(prefix, 'put', type, dispatch);
				await uplodToBucket(url.message, file);
				// call the api to update status
				await fileService.updateS3Status(
					{
						fileName: prefix,
						uploadStatus: 'Completed',
						note: 'file uploaded successfully.',
					},
					dispatch
				);
				// set fu_status to done
				setTimelineStatus((timelineStatus) => ({
					...timelineStatus,
					fu_done: 'green',
				}));

				// call the interval
				intervalId.current = setInterval(() => {
					pollProcessingStatus(prefix);
				}, pollTime * 1000);

				//call the setTimeout for poll timeout
				setTimeout(() => {
					if (intervalId.current) {
						setFile(null);
						notifyPopup(
							'error',
							'Error',
							'File Imported failed, Please retry after some time'
						);
						clearInterval(intervalId.current);
					}
				}, pollTimeOut * 60 * 1000);
				setForceFetch(!forceFetch);
			} catch (error) {
				// set fu_status to failed
				await fileService.updateS3Status(
					{
						fileName: prefix,
						uploadStatus: 'failed',
						note: error.response.data.error,
					},
					dispatch
				);
				setTimelineStatus((timelineStatus) => ({
					...timelineStatus,
					fu_done: 'red',
				}));
				notifyPopup('error', 'Error', error.response.data.error);
			}
		}
	};

	const onClickDone = () => {
		navigate(closeLink());
	};

	const onClickDownload = () => {
		const data = [
			{
				Tag_ID: '',
				Discipline: '',
				Description: '',
				Systems_Subsystems: '',
				Supplier: '',
			},
		];
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(data);
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Tags');
		XLSX.writeFile(workbook, `Tags-${moment().format('DD-MM-YYYY')}.xlsx`);
	};

	useEffect(() => {
		setFile(null);
		setTimelineStatus({
			fu_inprogress: 'grey',
			fu_done: 'grey',
			fp_inprogress: 'grey',
			fp_done: 'grey',
			fp_status: 'done/failed',
		});
		return () => {
			if (intervalId.current) {
				clearInterval(intervalId.current);
			}
		};
	}, [urlParams.type]);

	return (
		<div className='import-file-container'>
			<PageHeadingMultiButtons
				title={pageTitle}
				closeLink={closeLink()}
				desc={
					<h3 className='import-time'>
						Last Import {pageDate ? pageDate : ''}{' '}
					</h3>
				}
				subTitle
			/>
			<div className='import-section'>
				<h3 className='import-section-title'>Location</h3>
				<div onClick={onClick} className='import-input-container'>
					<input
						onChange={handleFileUpload}
						ref={inputRef}
						type={'file'}
						style={{ display: 'none' }}
					/>
					<h3 className='import-info-text'>
						{file ? file.name : 'Select a File'}
					</h3>
					<img src={searchIcon} alt='search' />
				</div>
				<div style={{ width: '100%' }}>
					<ImportTimeline timelineStatus={timelineStatus} />
				</div>
				<div
					style={{
						marginTop: '85px',
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<IconButton
						icon={uploadIcon}
						text={progress === 100 ? 'Done' : 'Upload'}
						onClick={progress !== 100 ? onClickOpenModal : onClickDone}
					/>
				</div>
			</div>
			<ToggleModal onClose={onCancel} visible={openModal}>
				<UploadModal onSuccess={onSubmit} onCancel={onCancel} />
			</ToggleModal>
			{urlParams.type === 'tags' && (
				<div className='download-section'>
					<h3 className='download-text'>
						Download Tags import template (*.xlsx)
					</h3>
					<IconButton
						icon={downloadIcon}
						text='Download'
						onClick={onClickDownload}
					/>
				</div>
			)}
		</div>
	);
}

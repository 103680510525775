import "./SearchHeader.scss";
import "store";

import { Dropdown, Input, Menu, Skeleton } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconLetter from "components/IconLetter";
import React from "react";
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import gearIcon from "../../assets/icons/gear-icon.png"
import { getSanitizedData } from 'helpers/utils';
import homeIcon from "../../assets/icons/Home.png"
import logo from "../../assets/icons/logo.svg"
import placeholderLogo from "assets/images/placeholder-logo.png"
import search from "../../assets/icons/search.svg";
import { triggerGetSearchHeader } from 'store/slices/search/searchHeader';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to={'/logout'}>Log out</Link>
    </Menu.Item>
  </Menu>
);

function SearchHeader({ setAdmin: setAdminFromProps, showAdmin, hideSearch = false, logo: logoFromProps, showProjectIcon, project, hideVersion = false }) {
  const role = useSelector((state) => state.auth.role)
  const userName = useSelector((state) => state.auth.username)
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [text, setText] = React.useState("")

 
  const projectBarSection = <>
    <span>
      <img style={{ height: "100%", width: "100px" }} src={logo} alt="logo" />
      {!hideVersion && (<p className="logo-version">{process.env.REACT_APP_VERSION}</p>)}     
    </span>
    
  </>

  const onChangeSearchKeyword = (value) => {
    const newValue = getSanitizedData(value);
    sessionStorage.setItem("searchText", newValue)
    setText(value)
  }

  const onClickAdminSwitch = () => {
    setAdminFromProps(!showAdmin)
    if (!showAdmin) {
      navigate('/admin/projects/update');
    } else {
      navigate('/project');
    }
  }

  function getUserName() {
    if (role) {
      return role.toUpperCase();
    }
    return "User";
  }

  function getSearchData(localText = null) {
    const finalText = localText ?? text
    if (finalText.trim()) {
      dispatch(triggerGetSearchHeader(getSanitizedData(finalText)))
      navigate("/search/result/");
    }
  }

  React.useEffect(() => {
    const storedSessionText = sessionStorage.getItem('searchText');
    if (storedSessionText && location.pathname.includes("/search")) {
      setText(storedSessionText)
    } else {
      setText("")
    }
  }, [])

  return (
    <div className="search-header" style={{ width: "100%" }}>
      <div className="logo-block">
        {projectBarSection}
      </div>
      <div className="search-center-block">
        {project && (
          <>
            {showProjectIcon === null && logoFromProps === null ?
              <Skeleton.Image actice style={{ height: "35px", width: "35px", marginRight: "12px" }} /> :
              <img src={showProjectIcon == false ? placeholderLogo : logoFromProps} alt="logo" />
            }
            <h3 className="project-name">{project.projectName}</h3>
          </>
        )}
      </div>
      {!hideSearch && (
        <div className="item-block">
          <div className="search-box">
            <Input
              placeholder="Search..."
              onChange={(e) => onChangeSearchKeyword(e.target.value)}
              value={text}
              onKeyDown={e => e.key === 'Enter' && getSearchData()}
              suffix={text ?
                <FontAwesomeIcon onClick={() => onChangeSearchKeyword('')} icon={faCircleXmark} /> :
                <img src={search} onClick={() => getSearchData()} />
              }
              id="global-search-input"
            />
          </div>
          {role === "projectadmin" && (
            <div onClick={onClickAdminSwitch} className="admin-switch">
              <img style={{ width: "35px", height: "auto" }} src={showAdmin ? homeIcon : gearIcon} alt="gear" />
            </div>
          )}
          <div className="author-box">
            <div>
              {/* <img src={userIcon} alt="My Happy SVG" /> */}
              <IconLetter letter={userName ? userName[0] : "A"} />
            </div>
            <div className="author-name">
              <div>
                <Dropdown overlay={menu} trigger={['click']}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <span>{userName}</span>
                    <span className="role">{getUserName()}</span><DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchHeader;

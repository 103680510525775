import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import AuthService from "services/auth.service";
import { setMessage } from "./message";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password, type }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password, type);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error) || error.response.data.error
      error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  const result = await AuthService.logout();
  return result;
});

const initialState = user
  ? { isLoggedIn: true, user, role: user.roleName, username: user.username, token: user.token }
  : { isLoggedIn: false, user: null, role: null, username: null, token: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.records;
      state.username = action.payload.records.username;
      state.role = action.payload.records.roleName;
      state.token = action.payload.records.token;
    },
    [login.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.role = null;
    },
    [logout.fulfilled]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.role = null;
      state.username = null;
      state.token = null;
    },
    [logout.rejected]: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.role = null;
      state.username = null;
      state.token = null;
    }
  },
});
const { reducer } = authSlice;
export default reducer;

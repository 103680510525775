import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ProjectService from "services/admin/project.service";
import notifyPopup from "helpers/notificationHelper";

export const getSearchHeader = createAsyncThunk(
  "search/admin",
  async (searchData, thunkAPI) => {
    try {
      const { data: values, dispatch } = searchData
      const data = await ProjectService.getSearchData(values, dispatch);
      return { data };
    } catch (error) {
      notifyPopup("error", "Notification", "We could not find anything matching your search.");
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue('error occured');
    }
  }
);

export const triggerGetSearchHeader = createAsyncThunk("search/trigger", (data) => {
  return { data }
})

const initialState = {
  searchList: [],
  metaData: {},
  error: '',
  triggerText: '',
  loading: true
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  extraReducers: {
    [getSearchHeader.pending]: (state) => {
      state.searchList = []
      state.loading = true
    },
    [getSearchHeader.fulfilled]: (state, action) => {
      state.searchList = action.payload.data.records.records;
      state.metaData = action.payload.data.records._metadata
      state.loading = false
    },
    [getSearchHeader.rejected]: (state) => {
      state.searchList = [];
      state.metaData = {};
      state.loading = false;
      state.error = 'We could not find anything matching your search.';
    },
    [triggerGetSearchHeader.fulfilled]: (state, action) => {
      state.triggerText = action.payload.data
    }
  },
});
const { reducer } = searchSlice;
export default reducer;
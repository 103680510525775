import './csu.scss';

import { AuthCheckDisplay, isClient } from 'guards/AuthProvider';
import { Col, Form, Input, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { getCSUDetail, linkCSU } from 'services/projectmgmt/csu.services';
import { searchDelay, searchRisk } from 'services/projectmgmt/tag.service';
import { useNavigate, useParams } from 'react-router-dom';

import DebounceSelect from 'helpers/DebounceSelect';
import { IconButton } from 'components/button/Button';
import PageHeadingMultiButtons from 'components/PageHeading/PageHeadingMultiButtons';
import downloadIcon from 'assets/icons/download.png';
import { getSystemParent } from 'services/admin/referenceData.service';
import notifyPopup from 'helpers/notificationHelper';
import { useDispatch } from 'react-redux';

async function fetchRisk(searchText) {
	if (searchText && searchText.trim()) {
		return searchRisk(searchText)
			.then((res) => res.records.list)
			.then((body) =>
				body.map((text) => ({
					label: text.riskName,
					value: text.id,
				}))
			)
			.catch(() => []);
	}
}

async function fetchDelay(searchText) {
	if (searchText && searchText.trim()) {
		return searchDelay(searchText)
			.then((res) => res.records.list)
			.then((body) =>
				body.map((text) => ({
					label: text.delayName,
					value: text.id,
				}))
			)
			.catch(() => []);
	}
}

export default function LinkCSU() {
	const [form] = Form.useForm();
	const formRef = React.useRef();
	const parentName = React.useRef({});
	const urlParams = useParams();
	const { csuId } = urlParams;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [allSystemParents, setAllSystemParents] = useState([]);
	const [fetchedSystems, setFetchedSystems] = useState([]);
	const [value, setValue] = React.useState([]);

	const closeLink = () => {
		return '/project/csu';
	};

	const handleParentChange = useCallback((option, index) => {
		const values = option.map((ele) => ele.value);
		const newParentName = {
			[index]: [...values],
		};

		parentName.current = newParentName;
	});

	const onSubmit = async () => {
		const formValues = await form.validateFields();
		const data = {
			csuId,
			systemSubSystemIds: [...new Set(parentName.current[0])].filter(Boolean),
			associatedRiskId: formValues.risk ? formValues.risk.value : null,
			associatedDelayId: formValues.delay ? formValues.delay.value : null,
			note: formValues.note ?? '',
		};
		await linkCSU(data, dispatch);
		notifyPopup('success', 'Success', 'CSU Updated successfully');
		navigate(closeLink());
	};

	const getRiskData = (riskValue) => {
		if (riskValue && isClient()) {
			return riskValue[0].label;
		} else if (riskValue) {
			return riskValue[0];
		}
		return undefined;
	};

	const fetchDetails = useCallback(async (id) => {
		const result = await getCSUDetail(id, dispatch);
		let riskData, delayData;
		if (result) {
			const data = result.records[0];
			if (data.associatedRiskId) {
				riskData = await fetchRisk(data.associatedRiskId);
			}
			if (data.associatedDelayId) {
				delayData = await fetchDelay(data.associatedDelayId);
			}
			form.setFieldsValue({
				activityName: data.csuActivityName,
				system: Array.isArray(data.systemSubSystem)
					? data.systemSubSystem.map((ele) => ele.id)
					: [],
				risk: getRiskData(riskData),
				delay: getRiskData(delayData),
				note: data.note,
			});
			handleParentChange(
				data.systemSubSystem.map((ele) => ({
					...ele,
					value: ele.id,
				})),
				0
			);
			setFetchedSystems(data.systemSubSystem);
		}
	}, []);

	const fetchAllParents = useCallback(async () => {
		const records = await getSystemParent(dispatch);
		if (records) {
			setAllSystemParents(records);
		}
	}, []);

	useEffect(() => {
		fetchDetails(csuId);
		fetchAllParents();
	}, [fetchDetails, csuId]);

	return (
		<div className='link-csu-container'>
			<PageHeadingMultiButtons
				title={'Link CSU Activity to System'}
				closeLink={closeLink()}
				subTitle
			/>
			<h3 className='detail-title'>Details</h3>
			<Form layout='vertical' form={form} ref={formRef}>
				<Form.Item label='Activity Name' name='activityName'>
					<Input disabled value={'CSU Activity Lane 1'} />
				</Form.Item>
				<Form.Item label='System(s)/Sub-System(s)' name='system'>
					<Select
						disabled={isClient()}
						mode='multiple'
						allowClear
						onChange={(value, option) => handleParentChange(option, 0)}
						defaultValue={
							fetchedSystems.length ? fetchedSystems.map((ele) => ele.id) : []
						}
					>
						{allSystemParents.map((data) => (
							<Option value={data.id} key={`${data.id}-index`}>
								{data.systemSubSystem}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item label='Delay' name='delay'>
							{isClient() ? (
								<Input disabled />
							) : (
								<DebounceSelect
									showSearch
									multiple={false}
									value={value}
									placeholder='Search'
									fetchOptions={fetchDelay}
									onChange={(newValue) => {
										setValue(newValue);
									}}
									style={{
										width: '100%',
									}}
								/>
							)}
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='Risk' name='risk'>
							{isClient() ? (
								<Input disabled />
							) : (
								<DebounceSelect
									showSearch
									multiple={false}
									value={value}
									placeholder='Search'
									fetchOptions={fetchRisk}
									onChange={(newValue) => {
										setValue(newValue);
									}}
									style={{
										width: '100%',
									}}
								/>
							)}
						</Form.Item>
					</Col>
				</Row>
				<Form.Item name='note' label='Notes'>
					<Input.TextArea
						disabled={isClient()}
						rows={7}
						placeholder='Type Text Here'
					/>
				</Form.Item>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<AuthCheckDisplay>
						{isClient() ? (
							''
						) : (
							<IconButton
								onClick={onSubmit}
								icon={downloadIcon}
								text={'Save'}
							/>
						)}
					</AuthCheckDisplay>
				</div>
			</Form>
		</div>
	);
}

import { API, getSanitizedData } from "helpers/utils";
import { getUserAndProjectData, isTokedExpired } from "services/util";

import store from "store";

const createRisk = (formData, dispatch = null) => {
  const userId = store.getState().auth.user.userId;

  const data = {
    userId: userId,
    riskName: getSanitizedData(formData.risk_name),
    assignedTo: formData.assigned_to.value,
    systemSubSystemId: formData.systemSubSystemId,
    riskStateBefore: formData.beforeRisk,
    riskStateAfter: formData.afterRisk,
  };

  if (formData.description) {
    data.description = getSanitizedData(formData.description);
  }

  if (formData.attachments) {
    data.attachments = formData.attachments.map((item, idx) => ({
      id: idx + 1,
      fileName: item.uploadFileName,
      action: "I",
    }));
  }

  return API().post("/api/risk/create", JSON.stringify(data))
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      throw error.response.data;
    });
};

const getEditRiskAttachments = (formData, data) => {
  if (formData.attachments && formData.attachments.length > 0) {
    const arr = [];
    formData.attachments.forEach((item, idx) => {
      if (item.uploadFileName) {
        arr.push({
          id: idx + 1,
          fileName: item.uploadFileName,
          action: "I",
        });
      }
    });
    data.attachments = arr;
  }
}

const getEditRiskRemovedFilelist = (formData, data) => {
  if (formData.removedFileList && formData.removedFileList.length > 0) {
    const arr = [];
    formData.removedFileList.forEach((item) => {
      if (Number.isInteger(item.uid)) {
        arr.push({
          id: item.uid,
          fileName: item.name,
          action: "R",
        });
      }
    });
    if (data.attachments && data.attachments.length > 0) {
      data.attachments = [...data.attachments, ...arr];
    } else {
      data.attachments = [...arr];
    }
  }
}

const editRisk = (formData, dispatch = null) => {
  const userId = store.getState().auth.user.userId;

  const data = {
    userId: userId,
    riskId: formData.riskId,
    riskName: getSanitizedData(formData.risk_name),
    assignedTo: formData.assigned_to.value,
    projectPhase: formData.project_phase,
    riskStateBefore: formData.beforeRisk,
    riskStateAfter: formData.afterRisk,
    systemSubSystemId: formData.systemSubSystemId
  };

  if (formData.description) {
    data.description = getSanitizedData(formData.description);
  }

  getEditRiskAttachments(formData, data)

  getEditRiskRemovedFilelist(formData, data)

  return API().patch('/api/risk/edit', JSON.stringify(data))
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      throw error.response.data;
    });
};

const listRisk = (formData, dispatch = null) => {
  let url = "/api/risk/get-risklist?" + "&page=" + formData.page + `&order=${formData.sortOrder}&page_size=10` + "&sortColumn=" + formData.sortColumn + "&searchText=" + formData.searchData;

  if ([0, 1].includes(formData.filterCompleteStatus)) {
    url += "&markCompleted=" + formData.filterCompleteStatus;
  }

  if (formData.assignedTo) {
    url += `&assignedTo=${formData.assignedTo}`
  }

  if (formData.serachData) {
    url += "&searchText=" + formData.serachData
  }

  return API().get(url).then((response) => {
    return response.data;
  }).catch(e => {
    if (e.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, e.response.data)
    }
    throw e.response.data;
  });
};

const getRiskDetail = (riskId, dispatch = null) => {
  const userId = store.getState().auth.user.userId;
  const urlString = "/api/risk/get-riskdetails?riskId=" + riskId + "&userId=" + userId;
  return API().get(urlString).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    throw error.response.data;
  });
};

const getRiskTasks = (formData, dispatch = null) => {
  const userId = store.getState().auth.user.userId;

  let url =
    "/api/risk/get-tasklist?page=" +
    formData.page +
    `&order=${formData.sortOrder}&page_size=10&riskId=` + formData.riskId +
    `&sortColumn=${formData.sortColumn}` +
    "&userId=" + userId;

  if (formData.filterUserId) {
    url += "&assignedTo=" + formData.filterUserId;
  }
  if ([0, 1].includes(formData.filterTaskCompleteStatus)) {
    url += "&markCompleted=" + formData.filterTaskCompleteStatus;
  }
  if (formData.searchData) {
    url += `&searchText=${formData.searchData}`
  }

  return API().get(url).then((response) => {
    return response.data;
  }).catch(err => {
    if (err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    }
    throw err.response.data;
  });
}

const getHisory = (riskId, dispatch = null) => {
  const userId = store.getState().auth.user.userId;
  const url = "/api/risk/get-riskhistory?riskId=" + riskId + "&userId=" + userId;
  return API().get(url)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      throw error.response.data;
    });
};

const postComment = (riskId, comment, taggedUsers, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const data = {
    riskId,
    userId,
    comment: getSanitizedData(comment),
    taggedUsers
  }

  return API().post('/api/risk/add-riskcomment', data)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      throw error.response.data;
    });
};

const closeRisk = (riskId) => {
  const userId = store.getState().auth.user.userId;
  const data = JSON.stringify({
    riskId: riskId,
    userId: userId
  })

  return API().patch('/api/risk/markcomplete', data)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error.response.data;
    });
}

const getTopRisks = (priority, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  let url = `/api/risk/gettoprisks?userId=${userId}&limit=5`;
  if (priority) {
    url += `&priority=${priority}`
  }
  return API().get(url)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      throw error.response.data;
    });
}

const getMitigatedRisks = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const projectId = (store.getState().project.projectDetail.projectId ||
    (sessionStorage.getItem("project") &&
      JSON.parse(sessionStorage.getItem("project")).projectId)) ?? 1;

  const urlString = `/api/risk/get-mitigated-risks-list?userId=${userId}&projectId=${projectId}&limit=5`

  return API().get(urlString)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      throw error.response.data;
    });
}

const riskStatusToggle = async (markComplete, id, dispatch = null) => {
  const { userId } = getUserAndProjectData();
  const data = {
    markComplete,
    userId,
    id
  }

  try {
    const response = await API().patch('/api/risk/updatestatus', JSON.stringify(data));
    return response.data;
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    throw error.response.data;
  }

}

const riskService = {
  createRisk,
  editRisk,
  listRisk,
  getRiskDetail,
  getRiskTasks,
  getHisory,
  postComment,
  closeRisk,
  getTopRisks,
  getMitigatedRisks,
  riskStatusToggle
};
export default riskService;

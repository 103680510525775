import './styles/App.scss';
import './styles/fonts.scss';
import './styles/theme-style.scss';

import AppRoute from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Provider store={store}>
					<AppRoute />
				</Provider>
			</BrowserRouter>
		</div>
	);
}
export default App;

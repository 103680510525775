import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Tooltip } from "antd";
export default function({helpIconTitle = ''}){
    return (
        <>
            <span> &nbsp;
                  <Tooltip title={helpIconTitle}>
                    <FontAwesomeIcon 
                      size="sm"
                      style={{color: 'blue'}} 
                      icon={faCircleQuestion} 
                    />
                  </Tooltip>
            </span>
        </>
    );
}
import { API, getSanitizedData } from "helpers/utils";

import { isTokedExpired } from "services/util";
import notifyPopup from 'helpers/notificationHelper';
import store from "store";

export const getSystemParent = async (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/project/get-system-subsystem-parent/?userId=${adminUserId}`

  return API().get(url)
    .then(res => {
      return res.data.records
    })
    .catch(err => {
      if (err.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, err.response.data)
      }
      console.log("error", err);
      return null
    })
}

export const getSystem = (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/project/get-system-subsystem/?userId=${adminUserId}`

  return API().get(url)
    .then(res => {
      return res.data.records
    })
    .catch(err => {
      if (err.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, err.response.data)
      }
      console.log("error", err);
      return null
    })
}

export const getDisciplines = (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/project/get-discipline/?userId=${adminUserId}`

  return API().get(url)
    .then(res => {
      return res.data.records
    })
    .catch(err => {
      if (err.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, err.response.data)
      }
      console.log("error", err);
      return null
    })
}

export const getSuppliers = (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/project/get-supplier/?userId=${adminUserId}`

  return API().get(url)
    .then(res => {
      return res.data.records
    })
    .catch(err => {
      if (err.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, err.response.data)
      }
      console.log("error", err);
      return null
    })
}

export const addSystem = async (values, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/project/add-edit-system-subsystem"
  for (const val of values) {
    val.systemSubSystem = val.systemSubSystem ? getSanitizedData(val.systemSubSystem) : null;
    val.description = val.description ? getSanitizedData(val.description) : null;
  }
  const data = {
    userId: adminUserId,
    records: values
  }
  try {
    await API().post(url, data);
    return true;
  } catch (err) {
    if (err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    } else if (err.toString().includes(400)) {
      notifyPopup("error", "Notification", 'Please fill mandatory data');
    } else {
      notifyPopup('error', 'Something went wrong', '');
    }
    console.log("error", err)
    return null;
  }
}

export const addDiscipline = async (values, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/project/add-edit-discipline"
  for (const val of values) {
    val.description = val.description ? getSanitizedData(val.description) : null;
    val.disciplineId = val.disciplineId ? getSanitizedData(val.disciplineId) : null;
  }
  const data = {
    userId: adminUserId,
    records: values
  }
  try {
    await API().post(url, data);
    return true
  } catch (err) {
    if (err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    } else if (err.toString().includes(400)) {
      notifyPopup("error", "Notification", 'Please fill mandatory data');
    } else {
      notifyPopup('error', 'Something went wrong', '');
    }
    console.log("error", err)
    return null;
  }
}

export const addSupliers = async (values, dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = "/api/project/add-edit-supplier"
  for (const val of values) {
    val.description = val.description ? getSanitizedData(val.description) : null;
    val.supplierId = val.supplierId ? getSanitizedData(val.supplierId) : null;
  }
  const data = {
    userId: adminUserId,
    records: values
  }
  try {
    await API().post(url, data);
    return true;
  } catch (err) {
    if (err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    } else if (err.toString().includes(400)) {
      notifyPopup("error", "Notification", 'Please fill mandatory data');
    } else {
      notifyPopup('error', 'Something went wrong', '');
    }
    console.log("error", err)
    return null;
  }
}

export const getLastActivity = async (dispatch = null) => {
  const adminUserId = store.getState().auth.user.userId;
  const url = `/api/project/get-ref-data-lastupdated?userId=${adminUserId}`

  return API().get(url)
    .then((res) => {
      return res.data
    })
    .catch(err => {
      if (err.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, err.response.data)
      }
      console.log("error", err);
      return null
    })
}

export const searchActivity = async (searchText, data, activityId = false, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  let urlString =
    "/api/project/search-activities?userId=" +
    userId +
    "&searchText=" +
    searchText +
    "&activityId=" +
    activityId

  if (data && data.taskId) {
    urlString += "&taskId=" + data.taskId
  }

  return API().get(urlString)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      console.log(error);
      throw error.response.data;
    });
};

import { API, getSanitizedData } from "helpers/utils";
import { getUserAndProjectData, isTokedExpired } from "services/util";

import moment from "moment";
import store from "store";

const searchUser = (searchText, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const url = "/api/task/search-user?userId=" + userId + "&searchText=" + searchText;

  return API().get(url)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      console.log(error);
      throw error.response.data;
    });
};

const searchPredecessor = (searchText, data, activityId = false, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  let urlString =
    "/api/task/search-predecessor?userId=" +
    userId +
    "&searchText=" +
    searchText +
    "&activityId=" +
    activityId

  if (data && data.taskId) {
    urlString += "&taskId=" + data.taskId
  }

  return API().get(urlString)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data)
      }
      console.log(error);
      throw error.response.data;
    });
};

const searchDelayRisk = (searchText) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const urlString = "/api/task/search-risks-delays?userId=" + userId + "&searchText=" + searchText;

  return API().get(urlString)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error.response.data;
    });
};

const getTaskCategories = () => {
  const userId = store.getState().auth.user.userId;
  return API().get("/api/task/get-categories?userId=" + userId)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error.response.data;
    });
};

const createTask = (formData, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  const mandatoryData = {
    userId: userId,
    taskName: getSanitizedData(formData.task_name),
    assignedTo: formData.assigned_to.value,
    startDate: moment(formData.startdate).format("YYYY-MM-DD"),
    finishDate: moment(formData.finishdate).format("YYYY-MM-DD"),
  };
  if (formData.note) {
    mandatoryData.note = getSanitizedData(formData.note);
  }
  if (formData.category) {
    mandatoryData.categoryId = formData.category;
  }
  if (formData.predecessor) {
    mandatoryData.predecessorId = formData.predecessor.value;
  }
  if (formData.assriskdelay) {
    if (formData.assriskdelay.label.includes("(Risk)")) {
      mandatoryData.associatedRiskId = parseInt(
        formData.assriskdelay.value.split("|")[0]
      );
    }
    if (formData.assriskdelay.label.includes("(Delay)")) {
      mandatoryData.associatedDelayId = parseInt(
        formData.assriskdelay.value.split("|")[0]
      );
    }
  }
  if (formData.attachments) {
    mandatoryData.attachments = formData.attachments.map(
      (item, idx) => ({
        id: idx + 1,
        fileName: item["uploadFileName"],
        action: "I",
      })
    );
  }

  const data = JSON.stringify(mandatoryData);

  return API().post("/api/task/create", data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });
};

const toggleTaskStatusData = () => {
  const projectId = (store.getState().project.projectDetail.projectId ||
    (sessionStorage.getItem("project") &&
      JSON.parse(sessionStorage.getItem("project")).projectId)) ?? 1;
  const userId = JSON.parse(sessionStorage.getItem("user")).userId;
  return { projectId, userId }
}

const getEditTaskAsterix = (formData, mandatoryData) => {
  if (formData.assriskdelay) {
    if (formData.assriskdelay.label.includes("(Risk)")) {
      mandatoryData.associatedRiskId = parseInt(
        formData.assriskdelay.value.split("|")[0]
      );
    }
    if (formData.assriskdelay.label.includes("(Delay)")) {
      mandatoryData.associatedDelayId = parseInt(
        formData.assriskdelay.value.split("|")[0]
      );
    }
  }
}

const getEditTaskAttachment = (formData, mandatoryData) => {
  if (formData.attachments && formData.attachments.length > 0) {
    const arr = [];
    formData.attachments.forEach((item, idx) => {
      if (item.uploadFileName) {
        arr.push({
          id: idx + 1,
          fileName: item.uploadFileName,
          action: "I",
        });
      }
    });
    mandatoryData.attachments = arr;
  }
}

const getEditTaskRemovedFileList = (formData, mandatoryData) => {
  if (formData.removedFileList && formData.removedFileList.length > 0) {
    const arr = [];
    formData.removedFileList.forEach((item) => {
      if (Number.isInteger(item.uid)) {
        arr.push({
          id: item.uid,
          fileName: item.name,
          action: "R",
        });
      }
    });
    if (mandatoryData.attachments && mandatoryData.attachments.length > 0) {
      mandatoryData.attachments = [...mandatoryData.attachments, ...arr];
    } else {
      mandatoryData.attachments = [...arr];
    }
  }
}

const editTask = (formData, taskId, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  const mandatoryData = {
    taskId: taskId,
    userId: userId,
    taskName: getSanitizedData(formData.task_name),
    assignedTo: formData.assigned_to.value,
    startDate: moment(formData.startdate).format("YYYY-MM-DD"),
    finishDate: moment(formData.finishdate).format("YYYY-MM-DD"),
    markCompleted: false,
  };
  if (formData.note) {
    mandatoryData.note = getSanitizedData(formData.note);
  }
  if (formData.category) {
    mandatoryData.categoryId = formData.category;
  }
  if (formData.predecessor) {
    mandatoryData.predecessorId = formData.predecessor.value;
  }

  getEditTaskAsterix(formData, mandatoryData)

  mandatoryData.markCompleted = formData.isTaskCompleted

  getEditTaskAttachment(formData, mandatoryData)

  getEditTaskRemovedFileList(formData, mandatoryData)
  const data = JSON.stringify(mandatoryData);

  return API().patch('/api/task/edit', data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });
};

const taskStatusToggle = async (markComplete, id, dispatch = null) => {
  const { userId } = getUserAndProjectData();
  const data = {
    markComplete,
    userId,
    id
  }

  try {
    const response = await API().patch('/api/task/updatestatus', JSON.stringify(data));
    return response.data;
  } catch (error) {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    throw error.response.data;
  }

}
const listTask = (formData, dispatch = null) => {
  let url = "/api/task/get-tasklist?page=" + formData.page + `&order=${formData.sortOrder}&sortColumn=${formData.sortColumn}&page_size=10`;

  if (formData.filterUserId) {
    url += "&assignedTo=" + formData.filterUserId
  }

  if (formData.serachData) {
    url += "&searchText=" + formData.serachData
  }

  if ([0, 1].includes(formData.filterTaskCompleteStatus)) {
    url += "&markCompleted=" + formData.filterTaskCompleteStatus
  }
  return API().get(url).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data)
    }
    throw error.response.data;
  });
};

const getDelayedTaskList = (delayId, dispatch = null) => {
  const projectId = (store.getState().project.projectDetail.projectId ||
    (sessionStorage.getItem("project") &&
      JSON.parse(sessionStorage.getItem("project")).projectId)) ?? 1;

  const url = "/api/task/get-taskdetails?projectId=" + projectId + "&delayId=" + delayId;
  return API().get(url).then((response) => {
    return response.data;
  }).catch(err => {
    if (err.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, err.response.data)
    }
    throw err.response.data;
  });
}

const getTaskDetail = (taskID, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  const url = "/api/task/get-taskdetails?taskId=" + taskID + "&userId=" + userId;
  return API().get(url).then((response) => {
    return response.data;
  }).catch(error => {
    if (error.response.data.status === 401 && dispatch) {
      isTokedExpired(dispatch, error.response.data);
    }
    throw error.response.data;
  });
};

const getTaskHisory = (taskId, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  const url = "/api/task/get-taskhistory?taskId=" + taskId + "&userId=" + userId;
  return API().get(url)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });
};

const postTaskComment = (taskTextID, comment, taggedUsers, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const data = {
    taskId: taskTextID,
    userId,
    comment: getSanitizedData(comment),
    taggedUsers
  }

  return API().post("/api/task/add-taskcomment", JSON.stringify(data))
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });
};

const searchTaskName = (string, dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const url = `/api/task/search-task-name?userId=${userId}&searchText=${string}`;
  return API().get(url)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });
}

const getTopTasks = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;
  const url = `/api/task/gettoptasks?userId=${userId}&limit=10`;
  return API().get(url)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });
}

const getLatestTaskTimeStamp = (dispatch = null) => {
  const userId = store.getState().auth.user?.userId ?? 1;

  const urlString = `/api/task/getlastesttaskdetails?userId=${userId}`
  return API().get(urlString)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.data.status === 401 && dispatch) {
        isTokedExpired(dispatch, error.response.data);
      }
      throw error.response.data;
    });

}

const taskService = {
  searchUser,
  searchPredecessor,
  searchDelayRisk,
  getTaskCategories,
  createTask,
  editTask,
  listTask,
  getTaskDetail,
  getTaskHisory,
  postTaskComment,
  getDelayedTaskList,
  searchTaskName,
  getTopTasks,
  getLatestTaskTimeStamp,
  toggleTaskStatusData,
  taskStatusToggle
};
export default taskService;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import TaskService from "services/projectmgmt/task.service";

export const listTask = createAsyncThunk(
  "tasks/list",
  async (formData, thunkAPI) => {
    try {
      const data = await TaskService.listTask(formData);
      return { data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const triggerFetchTaskList = createAsyncThunk("tasks/trigger", (data) => {
  return { data }
})

const initialState = {
  tasksList: [],
  trigger: false
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  extraReducers: {
    [listTask.fulfilled]: (state, action) => {
      state.tasksList = action.payload.data.records.records;
      state.tasksList_metadata = action.payload.data.records._metadata;
    },
    [triggerFetchTaskList.fulfilled]: (state, action) => {
      state.trigger = action.payload.data
    }
  },
});
const { reducer } = taskSlice;
export default reducer;
import './tags.scss';

import {
	ActionRoundCheckBoxButton,
	IconButton,
} from 'components/button/Button';
import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	createTagGroup,
	searchTagGroup,
} from 'services/projectmgmt/tagGroup.service';
import { getSanitizedData, trimValue } from 'helpers/utils';
import {
	neccessaryValidationMessage,
	spacevalidationMessage,
} from 'constants/formValidationRules';
import { searchDelay, searchRisk } from 'services/projectmgmt/tag.service';
import { useNavigate, useParams } from 'react-router-dom';

import DebounceSelect from 'helpers/DebounceSelect';
import FileUpload from 'helpers/FileUpload';
import PageHeadingMultiButtons from 'components/PageHeading/PageHeadingMultiButtons';
import downloadIcon from 'assets/icons/download.png';
import fileService from 'services/projectmgmt/file.service';
import notifyPopup from 'helpers/notificationHelper';
import { useDispatch } from 'react-redux';

const { Option } = Select;
async function fetchTagGroup(searchText, searchType = 'supplyChainMilestone') {
	if (searchText && searchText.trim()) {
		return searchTagGroup(searchText, searchType, false)
			.then((res) => res.records.list)
			.then((body) =>
				body
					.map((tag) => {
						if (tag.type === 'task') {
							return {
								label: tag.name,
								value: `${tag.id}`,
							};
						}
					})
					.filter(Boolean)
			)
			.catch(() => []);
	}
}

async function fetchRisk(searchText) {
	if (searchText && searchText.trim()) {
		return searchRisk(searchText)
			.then((res) => res.records.list)
			.then((body) =>
				body.map((text) => ({
					label: text.riskName,
					value: text.id,
				}))
			)
			.catch(() => []);
	}
}

async function fetchDelay(searchText) {
	if (searchText && searchText.trim()) {
		return searchDelay(searchText)
			.then((res) => res.records.list)
			.then((body) =>
				body.map((text) => ({
					label: text.delayName,
					value: text.id,
				}))
			)
			.catch(() => []);
	}
}

export default function AddTagGroup() {
	const [form] = Form.useForm();
	const formRef = React.useRef();
	const urlParams = useParams();
	const { link, type } = urlParams;
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [value, setValue] = useState([]);
	const [newAttachments, setNewAttachments] = useState([]);
	const [isSupply, setIsSupply] = useState(true);
	const [isFollowed, setIsFollowed] = useState(false);

	function filePutUrlCb(fileName) {
		return fileService.getFileUrl('TagGroup/' + fileName, 'put', '', dispatch);
	}

	const closeLink = () => {
		return '/project/tag-group';
	};

	const getRowData = (value, isNote = false) => {
		if (value && value.value) {
			return value.value;
		} else if (value && isNote) {
			return getSanitizedData(value);
		} else if (value) {
			return value;
		}
		return null;
	};

	const onSubmit = async () => {
		try {
			const formValues = await form.validateFields();
			Object.keys(formValues).forEach(
				(key) => (formValues[key] = trimValue(formValues[key]))
			);

			const data = {
				tagGroupType: formValues.tagGroupType,
				tagGroupName: getSanitizedData(formValues.tagGroupName),
				supplyChainMilestoneId:
					isSupply && formValues.supplyChainMilestone
						? formValues.supplyChainMilestone.value
						: null,
				mechanicalCompletionMilestoneId:
					!isSupply && formValues.supplyChainMilestone
						? formValues.supplyChainMilestone.value
						: null,
				associatedRiskId: getRowData(formValues.risk),
				associatedDelayId: getRowData(formValues.delay),
				note: getRowData(formValues.note, true),
				attachments: newAttachments,
				isFollowed,
			};

			const result = await createTagGroup(data, dispatch);
			notifyPopup('success', 'Success', 'Tag group added successfully');

			if (link) {
				sessionStorage.setItem(
					'link',
					JSON.stringify({
						[link]: {
							name: formValues.tagGroupName,
							tagGroupId: result.records.tagGroupId,
							milestoneName: formValues.supplyChainMilestone
								? formValues.supplyChainMilestone.label
								: 'NA',
						},
					})
				);

				if (type === 'add') {
					navigate('/project/tags/add');
				} else if (type === 'link') {
					navigate(`/project/tags/group`);
				} else if (
					type === 'edit' &&
					sessionStorage.getItem('edit-tag-values')
				) {
					const values = JSON.parse(sessionStorage.getItem('edit-tag-values'));
					navigate(`/project/tags/edit/${values.tagId}`);
				}
			} else {
				navigate('/project/tag-group');
			}
		} catch (error) {
			console.log('error', error);
			notifyPopup('error', 'Error', error.response.data.error);
		}
	};

	useEffect(() => {
		if (link && link === 'supplyChain') {
			form.setFieldsValue({
				tagGroupType: 'supplyChainMilestone',
			});
			setIsSupply(true);
		} else if (link && link === 'mechanicalCompletion') {
			form.setFieldsValue({
				tagGroupType: 'mechanicalCompletionMilestone',
			});
			setIsSupply(false);
		}
	}, [link]);

	return (
		<div className='add-tag-group-container'>
			<PageHeadingMultiButtons
				title={'New Tag Group'}
				closeLink={closeLink()}
				subTitle
				customButtonAction={
					<ActionRoundCheckBoxButton
						text={'Follow'}
						isActive={!isFollowed}
						applyHover={false}
						invertColor
						updateStatus={() => setIsFollowed(!isFollowed)}
					/>
				}
				customButtonPosition='flex-start'
				titleWidth='50%'
			/>
			<Form layout='vertical' form={form} ref={formRef}>
				<Form.Item label='Tag Group Type' name='tagGroupType'>
					<Select
						onChange={(value) => {
							setIsSupply(value === 'supplyChainMilestone');
						}}
						name='tagGroupType'
						disabled={!!link}
					>
						<Option value={'supplyChainMilestone'}>Supply Chain</Option>
						<Option value='mechanicalCompletionMilestone'>
							Mechanical Completion
						</Option>
					</Select>
				</Form.Item>
				<Form.Item
					label='Tag Group Name'
					name='tagGroupName'
					rules={[
						{
							required: true,
							message: neccessaryValidationMessage,
						},
						{
							whitespace: true,
							message: spacevalidationMessage,
						},
					]}
				>
					<Input placeholder='Type Name Here' />
				</Form.Item>
				<Form.Item
					label={
						isSupply
							? 'Supply Chain Milestone'
							: 'Mechanical Completion Milestone'
					}
					name='supplyChainMilestone'
				>
					<DebounceSelect
						showSearch
						multiple={false}
						value={value}
						placeholder=''
						fetchOptions={(text) =>
							fetchTagGroup(
								text,
								isSupply
									? 'supplyChainMilestone'
									: 'mechanicalCompletionMilestone'
							)
						}
						onChange={(newValue) => {
							setValue(newValue);
						}}
						style={{
							width: '100%',
						}}
					/>
				</Form.Item>
				<Form.Item label='Delay' name='delay'>
					<DebounceSelect
						showSearch
						multiple={false}
						value={value}
						placeholder='Select delay'
						fetchOptions={fetchDelay}
						onChange={(newValue) => {
							setValue(newValue);
						}}
						style={{
							width: '100%',
						}}
					/>
				</Form.Item>
				<Form.Item label='Risk' name='risk'>
					<DebounceSelect
						showSearch
						multiple={false}
						value={value}
						placeholder='Select risk'
						fetchOptions={fetchRisk}
						onChange={(newValue) => {
							setValue(newValue);
						}}
						style={{
							width: '100%',
						}}
					/>
				</Form.Item>
				<Form.Item label='Notes' name='note'>
					<Input.TextArea rows={7} placeholder='Type Text Here' />
				</Form.Item>
				<FileUpload cb={setNewAttachments} filePutUrlCb={filePutUrlCb} />
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						marginBottom: '20px',
					}}
				>
					<IconButton icon={downloadIcon} text='Save' onClick={onSubmit} />
				</div>
			</Form>
		</div>
	);
}
